import React from 'react';
import { RuleCondition } from '@totem/components/rulesEngine/types';

type Props = {
  condition: RuleCondition;
}

const RuleConditionDisplay = ({ condition }: Props) => {
  return <span>{condition.field} <b>{condition.operator}</b> <i>{condition.values.toString()}</i></span>
};

export default RuleConditionDisplay;
