import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import * as R from 'ramda';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import Notification from '@totem/components/common/Notification';
import OrganizationContainer from '@totem/components/common/organizationContext/OrganizationContainer';
import UserProfileContext from '@totem/components/UserProfileContext';
import { LoginProfile, User, UserProfile } from '@totem/types/user';
import {
  getAccountPayloadSync,
  getToken,
} from '@totem/utilities/accountUtilities';
import api from '@totem/utilities/api';
import authUtilities from '@totem/utilities/authUtilities';
import { LOGIN_PROFILE_ENDPOINT } from '@totem/utilities/endpoints';
import { isNotNull } from '@totem/utilities/common';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';

type Props = {
  exact?: boolean;
  path?: string;
  component: any;
  roleSet: string[];
};

const styles = {
  spinnerContainer: {
    height: '100%',
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const AuthorizedRoute = ({ component: Component, roleSet }: Props) => {
  const [refreshData, setRefreshData] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string>(null);

  const navigate = useNavigate();

  const [userProfile, setUserProfile] = useState<UserProfile>(null);

  const retrieveUserId = () => {
    if (!getToken()) {
      return 'LOGIN';
    }

    // TODO: Remove @ts-ignore : Token
    // @ts-ignore
    return getAccountPayloadSync().id;
  };

  const onCompleted = (user: UserProfile) => {
    const token = getAccountPayloadSync();
    // TODO: Remove @ts-ignore : Token
    // @ts-ignore
    const { role } = token;

    authUtilities.setUserRole(role);
    authUtilities.setUserRoleLevel(role);
    api.setAuthToken();

    setUserProfile(
      // sort user organizations by name
      R.over(R.lensPath(['organizations']), R.sortBy(R.prop('name')))(user),
    );

    const organizations = !user.organizations
      ? []
      : user.organizations.filter((org) => org.isActive);

    if (!user.isEmailVerified) {
      navigate('/unverified-email');
    } else if (R.isEmpty(organizations)) {
      console.log(JSON.stringify(user));
      navigate('/login');
      //navigate('/no-access');
    } else if (!user.id) {
      navigate('/login');
    } else if (!user.organization || user.organization.id === EMPTY_ID) {
      navigate('/switch-organization');
    } else if (!authUtilities.minimumRequiredRole(roleSet)) {
      const endpoint = authUtilities.isTenantUser()
        ? '/tenant-dashboard'
        : authUtilities.isVendorUser()
          ? '/vendor-dashboard'
          : '/dashboard';

      navigate(endpoint);
    }
  };

  const userId = retrieveUserId();

  useEffect(() => {
    if (refreshData) {
      setRefreshData(false);
      fetch(`${LOGIN_PROFILE_ENDPOINT}/${userId}/${EMPTY_ID}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => {
          if (res.status >= 400) {
            setError(res.statusText);
          }
          return res.json();
        })
        .then((result: LoginProfile) => {
          onCompleted(result.profile);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [refreshData]);

  useEffect(() => {
    if (userProfile?.id && !authUtilities.minimumRequiredRole(roleSet)) {
      const endpoint = authUtilities.isTenantUser()
        ? '/tenant-dashboard'
        : authUtilities.isVendorUser()
          ? '/vendor-dashboard'
          : '/dashboard';

      navigate(endpoint);
    }
  }, []);

  useEffect(() => {
    if (isNotNull(error)) {
      navigate('/login');
    }
    if (typeof error !== 'undefined') {
      if (error) {
        navigate('/login');
      }
    }
  }, [error]);

  if (userId === 'LOGIN') {
    return <Navigate to="/login" replace />;
  }

  if (!userProfile?.id) {
    return (
      <div style={styles.spinnerContainer}>
        <DetailSpinner />
      </div>
    );
  }

  return (
    <UserProfileContext.Provider value={{
      loading: isLoading,
      refetch: () => setRefreshData(true),
      setProfile: setUserProfile,
      userProfile
    }}>
      <OrganizationContainer>
        <div style={{ height: '100%' }}>
          <Notification />
          <Component />
        </div>
      </OrganizationContainer>
    </UserProfileContext.Provider>
  );
};

export default AuthorizedRoute;
