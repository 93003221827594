import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ToolOutlined } from '@ant-design/icons';
import { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { Card } from 'antd';
import * as R from 'ramda';

import colors from '@totem/styles/colors';
import { getDashboardPath } from '@totem/utilities/authUtilities';
import { controlSystemTypeMap } from '@totem/utilities/controlSystemsUtilities';

import ControlSystemDetailContext from '../ControlSystemDetailContext';

import ControlSystemActionMenu from './ControlSystemActionMenu';

import './controlSystemSidebar.css';
import SideBarTitle from '@totem/components/SideBarTitle';

const styles = {
  card: {
    width: '100%',
    marginBottom: '2rem',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.accent} 100% 0 0 0`,
    //borderImage: `${colors.gradient.turquoise} 100% 0 0 0`,
  },
  cardBody: {
    padding: 0,
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  infoIcon: {
    marginLeft: '0.6rem',
    cursor: 'pointer',
    color: colors.neutral.gray,
  },
  icon: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5rem',
    fontSize: '18px',
    // this is simply antd-blue with opacity
    background: 'rgba(24, 144, 255, 0.25)',
    color: colors.antd.blue,
    padding: '5px',
    borderRadius: '50%',
  },
};

const ControlSystemInfo = () => {
  const { controlSystem } = useContext(ControlSystemDetailContext);

  const { systemType, manufacturer, model } = controlSystem;

  const systemName = R.pathOr<string>(
    'Unknown',
    [systemType, 'text'],
    controlSystemTypeMap,
  );

  const SystemIcon = R.pathOr<React.ComponentType<AntdIconProps>>(
    ToolOutlined,
    [systemType, 'icon'],
    controlSystemTypeMap,
  );

  return (
    <>
      <Card
        title={
          <SideBarTitle>
            <div>Details</div>
            <ControlSystemActionMenu />
          </SideBarTitle>
        }
        style={styles.card}
        styles={{body: styles.cardBody, header: styles.cardHeader}}
      >
        <div styleName="card-section-container">
          <div styleName="card-section-label">System Details</div>
          <div styleName="card-row">
            <span styleName="card-label">Type:</span>
            <span styleName="card-value">
              <div styleName="card-row">
                <SystemIcon style={styles.icon} />
                <Link
                  to={`${getDashboardPath()}/controlsystems?systemType=${systemType}`}
                >
                  <div styleName="system-type">{systemName}</div>
                </Link>
              </div>
            </span>
          </div>
          <div>
            <span styleName="card-label">Manufacturer:</span>
            <span styleName="card-value">{manufacturer}</span>
          </div>
          <div>
            <span styleName="card-label">Model:</span>
            <span styleName="card-value">{model}</span>
          </div>
        </div>
      </Card>
    </>
  );
};

export default ControlSystemInfo;
