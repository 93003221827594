import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { User } from '@totem/components/users/container/types';
import colors from '@totem/styles/colors';
import UserAssignedWork from '@totem/components/users/UserAssignedWork';
import { ParseStringDate } from '@totem/utilities/dateUtilities';

import '../../../users.css';
import { WorkItem } from '@totem/components/surveyV2/work/types';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { isNotNull } from '@totem/utilities/common';

interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
}

const styles = {
  button: {
    background: colors.criticality.compliant,
    color: colors.neutral.white,
  },
};

const UserViewDetailsModal = ({ user, open, onClose }: Props) => {
  const [assignedWork, setAssignedWork] = useState<WorkItem[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open) {
      fetch(`${USERS_ENDPOINT}/${user.id}/work`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => {
          CheckResponseShowError(res);
          return res.json();
        })
        .then((result: WorkItem[]) => {
          setAssignedWork(result);
          setLoading(false);
        });
    }
  }, [user, open]);

  const userHasWork = isNotNull(assignedWork) && assignedWork.length > 0;

  return (
    <Modal
      title={<ModalTitle>User Details</ModalTitle>}
      open={open}
      onCancel={onClose}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button style={styles.button} onClick={onClose}>
            Okay
          </Button>
        </ModalFooter>
      }
    >
      <Alert
        message="This user has been removed from your account."
        type="error"
        showIcon
      />
      <div styleName="modal-content">
        <div styleName="modal-content-subtitle">Date Removed:</div>
        <div>
          {ParseStringDate(user.organization?.deactivatedAt).format('L')}
        </div>
      </div>
      <div styleName="modal-content">
        <div styleName="modal-content-subtitle">Reason for Removal:</div>
        <div>{user.organization?.deactivatedFor}</div>
      </div>
      {loading ? (
        <div styleName="modal-spinner-container">
          <DetailSpinner />
        </div>
      ) : (
        <div styleName="modal-content">
          {!!userHasWork && <UserAssignedWork userAssignedWork={assignedWork} />}
        </div>
      )}
    </Modal>
  );
};

export default UserViewDetailsModal;
