import { Filter } from '@totem/types/common';
import {
  DeviceFilters,
  DeviceQueryResult,
  DevicesConnectionInput,
} from '@totem/types/devices';
import { isNotNull } from '@totem/utilities/common';

const managedServicesOptions = [
  'Backup',
  'Advanced Management',
  'RMM - Server',
  'RMM - Workstation',
  'Threat Management',
];

export const sortStringAscending = (compA: string, compB: string) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};
export const sortStringDescending = (compA: string, compB: string) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const sortNumberAscending = (compA: number, compB: number) => {
  return compA === compB ? 0 : compA > compB ? 1 : -1;
};
export const sortNumberDescending = (compA: number, compB: number) => {
  return compA === compB ? 0 : compA > compB ? -1 : 1;
};

export const isManaged = (device: DeviceQueryResult) => {
  if (isNotNull(device)) {
    if (isNotNull(device.device.contractLines)) {
      for (let idx = 0; idx < device.device.contractLines.length; idx++) {
        // eslint-disable-next-line max-depth
        if (
          managedServicesOptions.includes(
            device.device.contractLines[idx].service,
          )
        ) {
          return true;
        }
      }
    }
  }

  return false;
};

export const buildDeviceFilters = (input: DevicesConnectionInput) => {
  const paramFilters: Filter[] = [];

  if (isNotNull(input.searchTerm) && input.searchTerm !== '') {
    paramFilters.push({
      field: 'displayName',
      operator: 'cscontains',
      values: [input.searchTerm],
    });
  }

  if (isNotNull(input.searchIpAddress) && input.searchIpAddress !== '') {
    paramFilters.push({
      field: 'ipAddresses',
      operator: 'cscontains',
      values: [input.searchIpAddress],
    });
  }

  if (isNotNull(input.isManaged) && input.isManaged.length > 0) {
    paramFilters.push({
      field: 'isManaged',
      values: input.isManaged,
    });
  }

  if (isNotNull(input.isMonitored) && input.isMonitored.length > 0) {
    paramFilters.push({
      field: 'flags.monitored.status',
      values: input.isMonitored,
    });
  }

  if (isNotNull(input.organizationId) && input.organizationId.length > 0) {
    paramFilters.push({
      field: 'organizationId',
      values: input.organizationId,
    });
  }
  if (isNotNull(input.regionId) && input.regionId.length > 0) {
    paramFilters.push({
      field: 'regionId',
      values: input.regionId,
    });
  }
  if (isNotNull(input.buildingId) && input.buildingId.length > 0) {
    paramFilters.push({
      field: 'buildingId',
      values: input.buildingId,
    });
  }
  if (isNotNull(input.controlSystemId) && input.controlSystemId.length > 0) {
    paramFilters.push({
      field: 'controlSystemId',
      values: input.controlSystemId,
    });
  }
  if (isNotNull(input.type) && input.type.length > 0) {
    paramFilters.push({
      field: 'type',
      values: input.type,
    });
  }
  if (isNotNull(input.vendor) && input.vendor.length > 0) {
    paramFilters.push({
      field: 'vendor',
      values: input.vendor,
    });
  }
  if (isNotNull(input.operatingSystem) && input.operatingSystem.length > 0) {
    paramFilters.push({
      field: 'operatingSystem',
      values: input.operatingSystem,
    });
  }
  if (isNotNull(input.model) && input.model.length > 0) {
    paramFilters.push({
      field: 'attribute:Model',
      values: input.model,
    });
  }
  if (isNotNull(input.noBackup) && input.noBackup.length > 0) {
    paramFilters.push({
      field: 'noBackup',
      values: input.noBackup,
    });
  }
  if (isNotNull(input.staleBackups) && input.staleBackups.length > 0) {
    paramFilters.push({
      field: 'staleBackups',
      values: input.staleBackups,
    });
  }
  if (isNotNull(input.noAntiVirus) && input.noAntiVirus.length > 0) {
    paramFilters.push({
      field: 'noVirusProtection',
      values: input.noAntiVirus,
    });
  }
  if (
    isNotNull(input.operatingSystemPastEndOfSupport) &&
    input.operatingSystemPastEndOfSupport.length > 0
  ) {
    paramFilters.push({
      field: 'operatingSystemEndOfSupport',
      values: input.operatingSystemPastEndOfSupport,
    });
  }
  if (
    isNotNull(input.operatingSystemApproachingEndOfSupport) &&
    input.operatingSystemApproachingEndOfSupport.length > 0
  ) {
    paramFilters.push({
      field: 'operatingSystemApproachingEndOfSupport',
      values: input.operatingSystemApproachingEndOfSupport,
    });
  }

  if (isNotNull(input.lowDiskSpace) && input.lowDiskSpace.length > 0) {
    paramFilters.push({
      field: 'lowDiskSpace',
      values: input.lowDiskSpace,
    });
  }

  return paramFilters;
};

export const addDeviceFilters = (
  currentFilters: Filter[],
  input: DeviceFilters,
) => {
  if (isNotNull(input)) {
    if (isNotNull(input.searchTerm) && input.searchTerm !== '') {
      currentFilters.push({
        field: 'displayName',
        operator: 'cscontains',
        values: [input.searchTerm],
      });
    }

    if (isNotNull(input.searchIpAddress) && input.searchIpAddress !== '') {
      currentFilters.push({
        field: 'ipAddresses',
        operator: 'cscontains',
        values: [input.searchIpAddress],
      });
    }

    if (isNotNull(input.isManaged) && input.isManaged.length > 0) {
      currentFilters.push({
        field: 'isManaged',
        values: input.isManaged,
      });
    }

    if (isNotNull(input.isMonitored) && input.isMonitored.length > 0) {
      currentFilters.push({
        field: 'device.flags.monitored.status',
        values: input.isMonitored,
      });
    }

    if (isNotNull(input.organizationId) && input.organizationId.length > 0) {
      currentFilters.push({
        field: 'organizationId',
        values: input.organizationId,
      });
    }
    if (isNotNull(input.regionId) && input.regionId.length > 0) {
      currentFilters.push({
        field: 'regionId',
        values: input.regionId,
      });
    }
    if (isNotNull(input.buildingId) && input.buildingId.length > 0) {
      currentFilters.push({
        field: 'buildingId',
        values: input.buildingId,
      });
    }
    if (isNotNull(input.controlSystemId) && input.controlSystemId.length > 0) {
      currentFilters.push({
        field: 'controlSystemId',
        values: input.controlSystemId,
      });
    }
    if (isNotNull(input.vendor) && input.vendor.length > 0) {
      currentFilters.push({
        field: 'vendor',
        values: input.vendor,
      });
    }
    if (isNotNull(input.type) && input.type.length > 0) {
      currentFilters.push({
        field: 'type',
        values: input.type,
      });
    }
    if (isNotNull(input.operatingSystem) && input.operatingSystem.length > 0) {
      currentFilters.push({
        field: 'operatingSystem',
        values: input.operatingSystem,
      });
    }
    if (isNotNull(input.model) && input.model.length > 0) {
      currentFilters.push({
        field: 'attribute:Model',
        values: input.model,
      });
    }

    if (isNotNull(input.noBackup) && input.noBackup.length > 0) {
      currentFilters.push({
        field: 'noBackup',
        values: input.noBackup,
      });
    }
    if (isNotNull(input.staleBackups) && input.staleBackups.length > 0) {
      currentFilters.push({
        field: 'staleBackups',
        values: input.staleBackups,
      });
    }
    if (isNotNull(input.noAntiVirus) && input.noAntiVirus.length > 0) {
      currentFilters.push({
        field: 'noAntiVirus',
        values: input.noAntiVirus,
      });
    }
    if (
      isNotNull(input.operatingSystemPastEndOfSupport) &&
      input.operatingSystemPastEndOfSupport.length > 0
    ) {
      currentFilters.push({
        field: 'operatingSystemEndOfSupport',
        values: input.operatingSystemPastEndOfSupport,
      });
    }
    if (
      isNotNull(input.operatingSystemApproachingEndOfSupport) &&
      input.operatingSystemApproachingEndOfSupport.length > 0
    ) {
      currentFilters.push({
        field: 'operatingSystemApproachingEndOfSupport',
        values: input.operatingSystemApproachingEndOfSupport,
      });
    }
    if (isNotNull(input.lowDiskSpace) && input.lowDiskSpace.length > 0) {
      currentFilters.push({
        field: 'lowDiskSpace',
        values: input.lowDiskSpace,
      });
    }
  }

  return currentFilters;
};
