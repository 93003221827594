import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ColumnProps } from 'antd/lib/table';

import Table from '@totem/components/common/table/Table';
import NetworkConcernsContext from '@totem/components/networkConcerns/networkConcernsContext';
import { NetworkInterfaceConcern } from '@totem/components/networkConcerns/types';
import { isNotNull } from '@totem/utilities/common';
import { sortStringAscending } from '@totem/utilities/tableUtilities';

type Props = {
  data: NetworkInterfaceConcern[];
};
const DeviceInterfaceTable = ({ data }: Props) => {
  const { loading } = useContext(NetworkConcernsContext);

  const columns: ColumnProps<NetworkInterfaceConcern>[] = [
    {
      title: 'Name',
      dataIndex: 'device.displayName',
      key: 'displayName',
      showSorterTooltip: true,
      render: (_, record: NetworkInterfaceConcern) => (
        <Link to={`/dashboard/devices/${record.device.device.id}`}>
          {record.device.device.displayName}
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.device.device.displayName,
          compB.device.device.displayName,
        ),
    },
    {
      title: 'Building',
      dataIndex: 'device.building.name',
      key: 'device.building.name',
      showSorterTooltip: true,
      render: (_, record: NetworkInterfaceConcern) =>
        record.device.building.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.device.building.name,
          compB.device.building.name,
        ),
    },
    {
      title: 'Interface Name',
      dataIndex: 'interfaces.interfaceName',
      key: 'interfaces.interfaceName',
      showSorterTooltip: true,
      render: (_, record: NetworkInterfaceConcern) => (
        <>
          {record.interfaces.map((netInf) => (
            <div>{netInf.interfaceName}</div>
          ))}
        </>
      ),
    },
    {
      title: 'MAC Addresses',
      dataIndex: 'interfaces.macAddress',
      key: 'interfaces.macAddress',
      showSorterTooltip: true,
      render: (_, record: NetworkInterfaceConcern) => (
        <>
          {record.interfaces.map((netInf) => (
            <div>{netInf.macAddress.toString()}</div>
          ))}
        </>
      ),
    },
    {
      title: 'IP Addresses',
      dataIndex: 'interfaces.ipAddress',
      key: 'interfaces.ipAddress',
      showSorterTooltip: true,
      render: (_, record: NetworkInterfaceConcern) => (
        <>
          {record.interfaces.map((netInf) => (
            <div>{netInf.ipAddress.toString()}</div>
          ))}
        </>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'interfaces.status',
      key: 'interfaces.status',
      showSorterTooltip: true,
      render: (_, record: NetworkInterfaceConcern) => (
        <>
          {record.interfaces.map((netInf) => (
            <div>{netInf.status}</div>
          ))}
        </>
      ),
    },
  ];

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(data) ? data : []}
      loading={loading}
      pagination={{
        showSizeChanger: true,
      }}
      rowKey={(record) => record.device.device.id}
    />
  );
};

export default DeviceInterfaceTable;
