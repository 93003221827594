import React, { useEffect, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button, Card } from 'antd';

import EnrollmentDialog from '@totem/components/settings/sections/profile/EnrollmentDialog';
import colors from '@totem/styles/colors';
import { AuthProfile, MultifactorEnrollment } from '@totem/types/auth';
import { getToken } from '@totem/utilities/accountUtilities';
import { AUTH_PROFILE_ENDPOINT } from '@totem/utilities/endpoints';

import './profile.css';

const styles = {
  form: {
    width: '100%',
    paddingLeft: '2.75rem',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '3rem',
    height: '72px',
    width: '72px',
  },
  cardStyle: {
    width: '100%',
    color: colors.neutral.white,
  },
  cardHeader: {
    padding: '0',
  },
  cardBody: {
    padding: '1.5rem',
  },
};

interface Props {
  userID: string;
}

const Multifactor = ({ userID }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isMultifactorLoadError, setIsMultifactorLoadError] = useState<boolean>(
    false,
  );
  const [refresh, setRefresh] = useState<boolean>(true);
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [authProfile, setAuthProfile] = useState<AuthProfile>(null);
  const [enrollment, setEnrollment] = useState<MultifactorEnrollment>(null);

  useEffect(() => {
    if (refresh === true) {
      setRefresh(false);
      setEnrollment(null);
      fetch(`${AUTH_PROFILE_ENDPOINT}/${userID}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => {
          if (res.status >= 400) {
            setIsMultifactorLoadError(true);
          }
          return res;
        })
        .then(res => res.json())
        .then((result: AuthProfile) => {
          setAuthProfile(result);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [userID, refresh]);

  useEffect(() => {
    if (
      typeof authProfile !== 'undefined' &&
      authProfile !== null &&
      typeof authProfile.multifactor !== 'undefined' &&
      authProfile.multifactor !== null &&
      authProfile.multifactor.length > 0
    ) {
      fetch(`${AUTH_PROFILE_ENDPOINT}/multifactor/${userID}`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => res.json())
        .then((result: MultifactorEnrollment) => {
          setEnrollment(result[0]);
        })
        .then(() => {
          setIsLoading(false);
        });
    }
  }, [userID, authProfile]);

  const handleMultiFactorEdit = () => {
    if (
      typeof authProfile !== 'undefined' &&
      authProfile !== null &&
      typeof authProfile.multifactor !== 'undefined' &&
      authProfile.multifactor !== null &&
      authProfile.multifactor.length > 0 &&
      !isLoading
    ) {
      setShowDialog(true);
    } else if (authProfile !== null && !isLoading) {
      setShowDialog(true);
    }
  };

  const handleEnrollmentUpdated = () => {
    setRefresh(true);
    setShowDialog(false);
  };

  return (
    <Card
      title={
        <div styleName="settings-group-header-container">
          <div styleName="settings-group-header-label">
            Multi-Factor Authentication
          </div>
          <span styleName="attribute-group-header-container">
            {!isLoading && !isMultifactorLoadError && authProfile !== null && (
              <Button
                onClick={handleMultiFactorEdit}
                shape="circle"
                icon={<EditOutlined />}
              />
            )}
          </span>
        </div>
      }
      style={styles.cardStyle}
      styles={{body: styles.cardBody, header: styles.cardHeader}}
      bordered={false}
    >
      <div styleName="attribute-list-container">
        <div styleName="attribute-list-row">
          <div styleName="attribute-label">Multi-Factor Enrollment:</div>
          <div styleName="attribute-value">
            {isLoading
              ? 'Checking...'
              : isMultifactorLoadError
              ? 'Error Checking Enrollment'
              : typeof authProfile !== 'undefined' &&
                authProfile !== null &&
                typeof authProfile.multifactor !== 'undefined' &&
                authProfile.multifactor !== null &&
                authProfile.multifactor.length > 0 &&
                !isLoading
              ? 'Enrolled'
              : 'Not Enrolled'}
          </div>
        </div>
        {typeof authProfile !== 'undefined' &&
          authProfile !== null &&
          typeof authProfile.multifactor !== 'undefined' &&
          authProfile.multifactor !== null &&
          authProfile.multifactor.length > 0 &&
          enrollment !== null && (
            <>
              <div styleName="attribute-list-row">
                <div styleName="attribute-label">Multi-Factor Method:</div>
                <div styleName="attribute-value">{enrollment.type}</div>
              </div>
              <div styleName="attribute-list-row">
                <div styleName="attribute-label">
                  Multi-Factor Enrollment Status:
                </div>
                <div styleName="attribute-value">{enrollment.status}</div>
              </div>
            </>
          )}
      </div>
      {showDialog && (
        <EnrollmentDialog
          userID={userID}
          visible={showDialog}
          isEnrolled={
            typeof authProfile !== 'undefined' &&
            authProfile !== null &&
            typeof authProfile.multifactor !== 'undefined' &&
            authProfile.multifactor !== null &&
            authProfile.multifactor.length > 0 &&
            !isLoading
          }
          onClose={() => setShowDialog(false)}
          onEnrollmentUpdated={handleEnrollmentUpdated}
        />
      )}
    </Card>
  );
};

export default Multifactor;
