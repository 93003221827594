import React, { ReactNode, useEffect, useState } from 'react';

import RulesContext from '@totem/components/rulesEngine/report/rulesContext';
import {
  FilterOptions,
  Rule,
  RuleConnectionInput,
} from '@totem/components/rulesEngine/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { RULES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponse } from '@totem/utilities/responseUtilities';

type Props = {
  children?: ReactNode;
};

const RulesContainer = ({ children }: Props) => {
  const [input, updateInput] = useState<RuleConnectionInput>({});
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    domain: [],
    trigger: [],
    action: [],
  });
  const [unfiltered, setUnfiltered] = useState<Rule[]>([]);
  const [data, setData] = useState<Rule[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const setInput = (updated: Partial<RuleConnectionInput>) => {
    updateInput((prevInput) => {
      const newInput = { ...prevInput, ...updated };
      return newInput;
    });
  };

  useEffect(() => {
    fetch(`${RULES_ENDPOINT}`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => CheckResponse(res))
      .then((res) => res.json())
      .then((result: Rule[]) => {
        setUnfiltered(result);
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (isNotNull(unfiltered) && unfiltered.length > 0) {
      const domains: string[] = [];
      const triggers: string[] = [];
      const actions: string[] = [];

      unfiltered.forEach((rule) => {
        if (!domains.includes(rule.domain)) {
          domains.push(rule.domain);
        }
        if (isNotNull(rule.trigger) && rule.trigger.length > 0) {
          rule.trigger.forEach((trigger) => {
            if (!triggers.includes(trigger)) {
              triggers.push(trigger);
            }
          });
        }
        if (isNotNull(rule.actions) && rule.actions.length > 0) {
          rule.actions.forEach((action) => {
            if (!actions.includes(action.type)) {
              actions.push(action.type);
            }
          });
        }
      });

      const localFilterOptions: FilterOptions = {
        domain: domains,
        trigger: triggers,
        action: actions,
      };

      setFilterOptions(localFilterOptions);
    }
  }, [unfiltered]);

  useEffect(() => {
    let filteredData = [...unfiltered];
    if (isNotNull(input.domain) && input.domain.length > 0) {
      filteredData = filteredData.filter((chk) =>
        input.domain.includes(chk.domain),
      );
    }
    if (isNotNull(input.trigger) && input.trigger.length > 0) {
      filteredData = filteredData.filter((chk) => {
        for (
          let triggerIdx = 0;
          triggerIdx < chk.trigger.length;
          triggerIdx++
        ) {
          if (input.trigger.includes(chk.trigger[triggerIdx])) {
            return true;
          }
        }
        return false;
      });
    }
    if (isNotNull(input.action) && input.action.length > 0) {
      filteredData = filteredData.filter((chk) => {
        for (let actionIdx = 0; actionIdx < chk.actions.length; actionIdx++) {
          if (input.action.includes(chk.actions[actionIdx].type)) {
            return true;
          }
        }
        return false;
      });
    }

    setData(filteredData);
  }, [unfiltered, input]);

  return (
    <RulesContext.Provider
      value={{
        rulesData: data,
        filterOptions,
        loading,
        input,
        setInput,
      }}
    >
      <div>{children}</div>
    </RulesContext.Provider>
  );
};

export default RulesContainer;
