import { Button, Checkbox, Form, Input, Modal, Select } from 'antd';
import * as R from 'ramda';
import { CloseOutlined } from '@ant-design/icons';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import colors from '@totem/styles/colors';
import { useNavigate } from 'react-router-dom';
import { OrganizationCreateInput, OrganizationType } from '@totem/types/organization';
import UserProfileContext from '@totem/components/UserProfileContext';
import { OrgUsersAndTemplates } from '@totem/components/login/types';
import { ORGANIZATION_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull, isNull } from '@totem/utilities/common';
import { validateURL } from '@totem/utilities/validation';
import { RequestOrganizationCreate } from '@totem/components/internalOnly/netSuitePending/types';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

import './registration.css';

const FormItem = Form.Item;
const { Option } = Select;

interface Errors {
  name?: string;
  domain?: string;
}

const styles = {
  footer: {
    display: 'flex' as 'flex',
    flexDirection: 'row' as 'row',
    justifyContent: 'space-between' as 'space-between',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
  icon: {
    marginRight: '1.5rem',
    fontSize: '24px',
    color: colors.opacity.black0_4,
  },
};

const initialOrganization: OrganizationCreateInput = {
  name: '',
  type: OrganizationType.Customer,
  domain: '',
  userIds: [],
  questionnaireTemplateIds: [],
};

type Props = {
  open: boolean;
  onCancel: () => void;
  onComplete: () => void;
}

const CreateOrganization = ({ open, onCancel, onComplete }: Props) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState<Errors>({});
  const [nmap, setNmap] = useState<boolean>(false);
  const [input, setInput] =
    useState<OrganizationCreateInput>(initialOrganization);
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<string>('');
  const [sendingCreate, setSendingCreate] = useState<boolean>(false);
  const [organizationOptions, setOrganizationOptions] = useState<OrgUsersAndTemplates>(null);
  const { userProfile, loading, setProfile } = useContext(UserProfileContext);

  const SYSTEMS_ADMINS_ROLE = 3;

  useEffect(() => {
    if (selectedOrganizationId !== '') {
      fetch(`${ORGANIZATION_ENDPOINT}/${selectedOrganizationId}/usersAndTemplates`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => {
          if (res.status >= 400) {
            console.log(res.statusText);
          }
          return res.json();
        })
        .then((result: OrgUsersAndTemplates) => {
          setOrganizationOptions(result);
          handleInputChange({ userIds: [], questionnaireTemplateIds: [] })
        });
    }
  }, [selectedOrganizationId]);

  const handleInputChange = (update: Partial<OrganizationCreateInput>) => {
    setInput((current) => ({ ...current, ...update }));
  };

  const sendCreateOrganization = useCallback(async (request: RequestOrganizationCreate) => {
    fetch(`${ORGANIZATION_ENDPOINT}/create`, {
      method: 'POST',
      headers: new Headers({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(request),
    }).then((res) => {
      const success = CheckResponseShowError(res);
      if (success) {
        onComplete();
      }
    });
  }, []);

  const isValidForm = () => {
    const { name, domain } = input;

    const formErrors: Errors = {};

    if (!name) {
      formErrors.name = 'Name is required.';
    }

    if (!domain) {
      formErrors.domain = 'Domain is required.';
    } else if (!validateURL(domain)) {
      formErrors.domain = 'Domain must be a valid web domain.';
    }

    setErrors(formErrors);
    return R.isEmpty(formErrors);
  };

  const handleSubmit = () => {
    if (isValidForm()) {
      // Send Create
      const payload: RequestOrganizationCreate = {
        name: input.name,
        domain: input.domain,
        type: input.type,
        keys: [],
        attributes: [],
        copyOptions: {
          fromOrganizationId: selectedOrganizationId,
          userIds: input.userIds,
          templateIds: input.questionnaireTemplateIds,
        }
      }

      sendCreateOrganization(payload);
    }
  };

  const handleCancel = () => {
    onCancel();
  }

  const handleTemplateOrgChange = (organizationId: string) => {
    setSelectedOrganizationId(organizationId);
  }

  return (
    <Modal
      open
      style={{ top: '20vh', border: 'none' }}
      styles={{ body: { border: 'none' } }}
      title={
        <div styleName="organization-switcher-modal-title">
          Create New Account
        </div>
      }
      confirmLoading={sendingCreate}
      footer={
          <div style={styles.footer}>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button
              type="primary"
              onClick={handleSubmit}
              loading={sendingCreate}
            >
              Create
            </Button>
          </div>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Organization Name"
          colon={false}
          validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
          help={R.isNil(errors.name) ? null : errors.name}
          style={styles.formItem}
        >
          <Input
            placeholder="Enter Organization Name"
            value={input.name}
            onChange={(event) =>
              handleInputChange({ name: event.target.value })
            }
            autoComplete="off"
          />
        </FormItem>
        <FormItem
          label="Organization Domain"
          colon={false}
          validateStatus={R.isNil(errors.domain) ? 'success' : 'error'}
          help={R.isNil(errors.domain) ? null : errors.domain}
          style={styles.formItem}
        >
          <Input
            name="domain"
            placeholder="Organization Domain"
            onChange={(event) =>
              handleInputChange({ domain: event.target.value })
            }
            value={input.domain}
            autoComplete="off"
          />
        </FormItem>
        <FormItem
          label="Duplicate Template Account"
          colon={false}
          style={styles.formItem}
        >
          <Select
            showSearch
            filterOption
            optionFilterProp="children"
            onChange={handleTemplateOrgChange}
          >
            {userProfile.organizations
              .filter(
                (org) => org.isActive && org.role === SYSTEMS_ADMINS_ROLE,
              )
              .map(({ id, name }) => {
                return (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                );
              })}
          </Select>
        </FormItem>
        <FormItem
          label="Add existing team members"
          colon={false}
          style={{ padding: 0, margin: 0 }}
        >
          <Select
            showSearch
            filterOption
            mode="multiple"
            optionFilterProp="children"
            value={input.userIds}
            maxTagCount={0}
            maxTagPlaceholder={
              <div>
                {`${input.userIds.length} team members selected`}
                <CloseOutlined
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() => handleInputChange({ userIds: [] })}
                />
              </div>
            }
            onSelect={(userId) =>
              handleInputChange({ userIds: [...input.userIds, userId] })
            }
            onDeselect={(userId) =>
              handleInputChange({
                userIds: input.userIds.filter((id) => id !== userId),
              })
            }
            disabled={ isNull(organizationOptions) || isNull(organizationOptions.users) }
          >
            {isNotNull(organizationOptions) && isNotNull(organizationOptions.users) && organizationOptions.users
              .sort((a, b) => (a.name < b.name ? -1 : 1))
              .map(({ id, name }) => {
                return (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                );
              })}
          </Select>
        </FormItem>
        <div
          onClick={() =>
            handleInputChange({
              userIds: organizationOptions.users.map((user) => user.id),
            })
          }
          styleName={`select-all-button ${
            isNull(organizationOptions) || isNull(organizationOptions.users) ||
            !organizationOptions.users.length || organizationOptions.users.length === input.userIds.length
              ? 'disabled'
              : ''
          }`}
        >
          Select All
        </div>
        <FormItem
          label="Add survey templates from an existing account"
          colon={false}
          style={{ padding: 0, margin: 0 }}
        >
          <Select
            showSearch
            filterOption
            mode="multiple"
            optionFilterProp="children"
            value={input.questionnaireTemplateIds}
            maxTagCount={0}
            maxTagPlaceholder={
              <div>
                {`${input.questionnaireTemplateIds.length} surveys selected`}
                <CloseOutlined
                  style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
                  onClick={() =>
                    handleInputChange({ questionnaireTemplateIds: [] })
                  }
                />
              </div>
            }
            onSelect={(questionnaireTemplateId) =>
              handleInputChange({
                // @ts-ignore
                questionnaireTemplateIds: [
                  ...input.questionnaireTemplateIds,
                  questionnaireTemplateId,
                ],
              })
            }
            onDeselect={(questionnaireTemplate) =>
              handleInputChange({
                // @ts-ignore
                questionnaireTemplateIds:
                  input.questionnaireTemplateIds.filter(
                    (id) => id !== questionnaireTemplate,
                  ),
              })
            }
            disabled={isNull(organizationOptions) ||  isNull(organizationOptions.templates)}
          >
            {isNotNull(organizationOptions) && isNotNull(organizationOptions.templates) && organizationOptions.templates.map(({ id, name }) => {
              return (
                <Option key={id} value={id}>
                  {name}
                </Option>
              );
            })}
          </Select>
        </FormItem>
        <div
          onClick={() =>
            handleInputChange({
              // @ts-ignore
              questionnaireTemplateIds: templates.map(
                (template) => template.id,
              ),
            })
          }
          styleName={`select-all-button ${
            isNull(organizationOptions) || isNull(organizationOptions.templates) ||
            !organizationOptions.templates.length ||
            organizationOptions.templates.length === input.questionnaireTemplateIds.length
              ? 'disabled'
              : ''
          }`}
        >
          Select All
        </div>
        <Checkbox
          style={{ marginRight: '0.5rem' }}
          checked={nmap}
          onChange={(event) => setNmap(event.target.checked)}
        />
        <span> Check to enable NMAP's for buildings.</span>
      </Form>
    </Modal>
  );
};

export default CreateOrganization;
