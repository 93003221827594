import React from 'react';

import LoginLogo from '@totem/components/logos/LoginLogo';

import './registration.css';
import SwitchOrganizationsDialog from '@totem/components/login/switchOrganizationDialog';

const SwitchOrganization = () => (
  <div styleName="root-container">
    <div className="middle-box text-center loginscreen animate__animated animate__fadeInDown">
      <div>
        <div styleName="login-header">
          <LoginLogo />
        </div>
      </div>
    </div>
    <SwitchOrganizationsDialog />
  </div>
);

export default SwitchOrganization;
