import React, { useCallback, useEffect, useState } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import colors from '@totem/styles/colors';
import { User } from '@totem/components/users/container/types';

import '../../../users.css';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { WorkItem } from '@totem/components/surveyV2/work/types';
import { isNotNull } from '@totem/utilities/common';
import UserAssignedWork from '@totem/components/users/UserAssignedWork';

interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
}

const getMessage = (email: string, hasWork: boolean) => {
  if (hasWork) {
    return `Are you sure you want to remove site access for ${email}? This user has been assigned work and their email will still be listed as an assignee. If you remove this user, their site access will be revoked and they will no longer be able to access work from your organization.`;
  }

  return `Are you sure you want to remove ${email} from your site? They will no longer have access to your organization.`;
};

const styles = {
  button: {
    background: colors.utility.error,
    color: colors.neutral.white,
  },
  errorIcon: {
    color: colors.utility.error,
    marginRight: '1rem',
    fontSize: '22px',
  },
};

const UserRemoveModal = ({ open, onClose, user: { id, email } }: Props) => {
  const [sending, setSending] = useState(false);
  const [deactivatedFor, setDeactivatedFor] = useState<string>('');
  const [assignedWork, setAssignedWork] = useState<WorkItem[]>([]);

  const sendDeactivateUser = useCallback((userId: string, reason: string)=> {
    if (!sending) {

      const payload = {
        reason: reason,
      }

      setSending(true);
      fetch(`${USERS_ENDPOINT}/${userId}/deactivate`, {
        method: 'PATCH',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then(res => {
          setSending(false);
          CheckResponseShowError(res);
          onClose();
        });
    }
  }, []);

  useEffect(() => {
    if (open) {
      fetch(`${USERS_ENDPOINT}/${id}/work`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => {
          CheckResponseShowError(res);
          return res.json();
        })
        .then((result: WorkItem[]) => {
          setAssignedWork(result);
        });
    } else {
      setDeactivatedFor('');
    }
  }, [id, open]);

  const handleSubmit = async () => {
    sendDeactivateUser(id, deactivatedFor);
  };

  const userHasWork = isNotNull(assignedWork) && assignedWork.length > 0;

  return (
    <Modal
      title={
        <ModalTitle>
          <CloseCircleFilled style={styles.errorIcon} />
          Remove User
        </ModalTitle>
      }
      onCancel={onClose}
      open={open}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            style={styles.button}
            loading={sending}
            onClick={handleSubmit}
            disabled={!deactivatedFor}
          >
            Remove
          </Button>
        </ModalFooter>
      }
    >
      {sending ? (
        <div styleName="modal-spinner-container">
          <DetailSpinner color={colors.utility.error} />
        </div>
      ) : (
        <>
          <div>{getMessage(email, !!userHasWork)}</div>
          <div styleName="modal-input">
            <div>Why is this user being removed?</div>
            <Input.TextArea
              value={deactivatedFor}
              onChange={(event) => setDeactivatedFor(event.target.value)}
              rows={4}
            />
          </div>
          {!!userHasWork && <UserAssignedWork userAssignedWork={assignedWork} />}
        </>
      )}
    </Modal>
  );
};

export default UserRemoveModal;
