import actionTypes from './actionTypes';

export const createRegionSuccess = data => ({
  type: actionTypes.CREATE_REGION_SUCCESS,
  data,
});

export const createRegionFailure = () => ({
  type: actionTypes.CREATE_REGION_FAILURE,
});

export const getRegionsSuccess = data => ({
  type: actionTypes.REGIONS_GET_SUCCESS,
  data,
});

export const getRegionsFailure = () => ({
  type: actionTypes.REGIONS_GET_FAILURE,
});

export const getRegionsTotalSuccess = data => ({
  type: actionTypes.REGIONS_TOTAL_GET_SUCCESS,
  data,
});

export const regionUpdateSuccess = data => ({
  type: actionTypes.REGION_UPDATE_SUCCESS,
  data,
});

export const regionUpdateFailure = () => ({
  type: actionTypes.REGION_UPDATE_FAILURE,
});
