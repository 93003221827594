import React, { useContext, useEffect, useState } from 'react';
import { Collapse, CollapseProps, Empty, Flex } from 'antd';

import TagPanel from '@totem/components/software/software_report/byTag/tagPanel';
import SoftwareContext from '@totem/components/software/software_report/softwareContext';
import { isNotNull, isNull } from '@totem/utilities/common';

const TagsSection = () => {
  const [tags, setTags] = useState<string[]>([]);
  const { data } = useContext(SoftwareContext);

  useEffect(() => {
    const taggedSoftware = data.filter(
      (chk) => isNotNull(chk.tags) && chk.tags.length > 0,
    );

    const newTags: string[] = [];

    for (let packageIdx = 0; packageIdx < taggedSoftware.length; packageIdx++) {
      for (
        let tagIdx = 0;
        tagIdx < taggedSoftware[packageIdx].tags.length;
        tagIdx++
      ) {
        if (
          newTags.findIndex(
            (chk) => chk === taggedSoftware[packageIdx].tags[tagIdx],
          ) < 0
        ) {
          newTags.push(taggedSoftware[packageIdx].tags[tagIdx]);
        }
      }
    }
    setTags(newTags);
  }, [data]);

  const getItems = () => {
    const items: CollapseProps['items'] = tags.map((tag) => {
      const filtered = data.filter((chk) =>
        isNull(chk.tags)
          ? false
          : chk.tags.findIndex((chk2) => chk2 === tag) >= 0,
      );

      return {
        key: tag,
        label: (
          <Flex justify="space-between">
            <div>{tag}</div>
            <div>{filtered.length}</div>
          </Flex>
        ),
        children: <TagPanel tag={tag} packages={filtered} />,
      };
    });

    const filtered = data.filter(
      (chk) => isNull(chk.tags) || chk.tags.length === 0,
    );

    items.push({
      key: 'Untagged',
      label: (
        <Flex justify="space-between">
          <div>Untagged</div>
          <div>{filtered.length}</div>
        </Flex>
      ),
      children: <TagPanel tag={''} packages={filtered} />,
    });

    return items;
  };

  if (isNull(data) || data.length === 0) {
    return <Empty />;
  }

  return (
    <div>
      <Collapse items={getItems()} defaultActiveKey={['1']} />
      <br />
    </div>
  );
};

export default TagsSection;
