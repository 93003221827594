import { createContext } from 'react';

import { NetworkConcerns } from '@totem/components/networkConcerns/types';

interface Context {
  reportData: NetworkConcerns;
  loading: boolean;
}

export default createContext<Context>({
  loading: false,
  reportData: null,
});
