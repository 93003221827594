import React, { useCallback, useContext, useState } from 'react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Select,
} from 'antd';
const FormItem = Form.Item;
import dayjs from 'dayjs';
import * as R from 'ramda';

import ControlSystemAutoCompleteFromOrgContext, {
  ControlSystem,
} from '@totem/components/common/autoCompletes/ControlSystemAutoCompleteFromOrgContext';
import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import OrganizationContext from '@totem/components/common/organizationContext/organizationContext';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
import TemplatesContext from '@totem/components/surveyV2/templates/TemplatesContext';
import fonts from '@totem/styles/fonts';
import { AutoCompleteValue } from '@totem/types/autoComplete';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { validateEmail } from '@totem/utilities/validation';

const styles = {
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
  datePickersContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  datePicker: {
    width: '100%',
  },
  modalHeader: {
    fontWeight: 700,
    fontSize: fonts.fontXl,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

type FormErrors = {
  name?: string;
  building?: string;
  template?: string;
  assignee?: string;
};

type Props = {
  open: boolean;
  onClose: () => void;
  building?: AutoCompleteValue;
  controlSystem?: ControlSystem;
};

const AddSurveyDialog = ({ open, onClose, building, controlSystem }: Props) => {
  const { data: templates, loading: templateLoading } =
    useContext(TemplatesContext);
  const { loading: organizationLoading } = useContext(OrganizationContext);
  const { onAction, loading, setIsLoading } = useContext(SurveyInstanceContext);
  const [surveyType, setSurveyType] = useState<string>('survey');
  const [selectedBuilding, setSelectedBuilding] = useState<AutoCompleteValue>(
    building === null ? null : building,
  );
  const [selectedControlSystem, setSelectedControlSystem] =
    useState<ControlSystem>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>('');
  const [surveyName, setSurveyName] = useState<string>('');
  const [assignee, setAssignee] = useState<string>('');
  const [startDate, setStartDate] = useState<number>(0);
  const [dueDate, setDueDate] = useState<number>(0);
  const [errors, setErrors] = useState<FormErrors>({});

  const sendCreateSurveyInstance = useCallback(async (payload) => {
    fetch(`${V2_SURVEY_ENDPOINT}/createInstance`, {
      method: 'POST',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
      body: JSON.stringify(payload),
    }).then((res) => {
      setIsLoading(false);
      CheckResponseShowError(res);
      onAction('refresh', null);
      onClose();
    });
  }, []);
  const isValid = async (): Promise<boolean> => {
    const newErrors: FormErrors = {};

    if (R.isEmpty(selectedBuilding)) {
      newErrors.building = 'Please select a building';
    }

    if (R.isEmpty(selectedTemplateId)) {
      newErrors.template = 'Please select a template';
    }

    if (assignee && !validateEmail(assignee)) {
      newErrors.assignee = 'Please enter a valid email address';
    }

    if (R.isEmpty(surveyName)) {
      newErrors.name = 'Please enter a name';
    }

    setErrors(newErrors);
    return R.isEmpty(errors);
  };
  const handleSubmit = () => {
    const isFormValid = isValid();

    if (isFormValid) {
      const payload = {
        templateId: selectedTemplateId,
        regionId: R.isEmpty(selectedBuilding)
          ? EMPTY_ID
          : selectedBuilding.parent.value,
        buildingId: R.isEmpty(selectedBuilding)
          ? EMPTY_ID
          : selectedBuilding.value,
        controlSystemId: R.isEmpty(selectedControlSystem)
          ? EMPTY_ID
          : selectedControlSystem.id,
        name: surveyName,
        startDate,
        dueDate,
        assignee,
      };

      setIsLoading(true);
      sendCreateSurveyInstance(payload);
    }
  };

  const parseDate = (date: number): dayjs.Dayjs | null => {
    if (!date) {
      return null;
    }

    return dayjs(date);
  };
  const getTemplateItems = () => {
    return templates
      .filter((template) => template.type === surveyType)
      .map((option) => {
        return {
          label: option.name,
          value: option.id,
        };
      });
  };

  return (
    <Modal
      title={<div style={styles.modalHeader}>Add Survey</div>}
      open={open}
      footer={
        <div style={styles.modalFooter}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="primary"
            disabled={loading}
            loading={templateLoading || organizationLoading || loading}
            onClick={handleSubmit}
          >
            Add Now
          </Button>
        </div>
      }
      onCancel={onClose}
    >
      <div style={styles.content}>
        <Form layout="vertical" style={styles.form}>
          <FormItem label="Type" colon={false} style={styles.formItem}>
            <Radio.Group
              value={surveyType}
              onChange={(event: RadioChangeEvent) => {
                setSurveyType(event.target.value);
                setSelectedTemplateId('');
              }}
            >
              <Radio value={'survey'}>Survey</Radio>
              <Radio value={'policyAudit'}>Policy Audit</Radio>
            </Radio.Group>
          </FormItem>

          <FormItem
            label="Building"
            colon={false}
            validateStatus={R.isNil(errors.building) ? 'success' : 'error'}
            help={R.isNil(errors.building) ? null : errors.building}
            style={styles.formItem}
          >
            {building ? (
              <Input value={building.display} disabled />
            ) : (
              <GenericAutoComplete
                type="Building"
                selectedValue={selectedBuilding}
                onSelect={setSelectedBuilding}
                onChange={() => setSelectedBuilding(null)}
                label="Building"
                limit={15}
                disabled={false}
              />
            )}
          </FormItem>

          <FormItem
            label="Control System"
            colon={false}
            validateStatus={'success'}
            style={styles.formItem}
          >
            {controlSystem ? (
              <Input value={controlSystem.name} disabled />
            ) : (
              <ControlSystemAutoCompleteFromOrgContext
                regionId={
                  R.isNil(selectedBuilding)
                    ? null
                    : selectedBuilding.parent.value
                }
                buildingId={
                  R.isNil(selectedBuilding) ? null : selectedBuilding.value
                }
                disabled={R.isNil(selectedBuilding)}
                value={
                  R.isNil(selectedControlSystem)
                    ? ''
                    : selectedControlSystem.name
                }
                onChange={() => {}}
                onSelect={setSelectedControlSystem}
              />
            )}
          </FormItem>

          <FormItem
            label="Template"
            colon={false}
            validateStatus={R.isNil(errors.template) ? 'success' : 'error'}
            help={R.isNil(errors.template) ? null : errors.template}
            style={styles.formItem}
          >
            <Select
              showSearch
              placeholder="Select Template"
              options={getTemplateItems()}
              onChange={(val: string) => setSelectedTemplateId(val)}
              value={selectedTemplateId || undefined}
              optionFilterProp="children"
              filterOption
            />
          </FormItem>
          <FormItem
            label="Survey Name"
            colon={false}
            validateStatus={R.isNil(errors.name) ? 'success' : 'error'}
            help={R.isNil(errors.name) ? null : errors.name}
            style={styles.formItem}
          >
            <Input
              placeholder="Enter Name"
              value={surveyName}
              onChange={({
                target: { value },
              }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                setSurveyName(value)
              }
              disabled={
                R.isEmpty(selectedBuilding) || R.isEmpty(selectedTemplateId)
              }
              type="search"
            />
          </FormItem>
          <div
            // @ts-ignore
            style={styles.datePickersContainer}
          >
            <FormItem
              label="Start Date"
              colon={false}
              style={{
                ...styles.formItem,
                width: '50%',
                marginRight: '1rem',
              }}
            >
              <DatePicker
                onChange={(date) => setStartDate(date ? date.valueOf() : 0)}
                // @ts-ignore
                value={parseDate(startDate)}
                style={styles.datePicker}
              />
            </FormItem>
            <FormItem
              label="Due Date"
              colon={false}
              style={{
                ...styles.formItem,
                width: '50%',
                marginLeft: '1rem',
              }}
            >
              <DatePicker
                onChange={(date) => setDueDate(date ? date.valueOf() : 0)}
                // @ts-ignore
                value={parseDate(dueDate)}
                style={styles.datePicker}
              />
            </FormItem>
          </div>
          <FormItem
            label="Assignee Email"
            colon={false}
            validateStatus={R.isNil(errors.assignee) ? 'success' : 'error'}
            help={R.isNil(errors.assignee) ? null : errors.assignee}
            style={styles.formItem}
          >
            <GenericAutoComplete
              type="User"
              selectedValue={{ display: assignee, value: assignee }}
              onSelect={(selectedUser: AutoCompleteValue) =>
                setAssignee(selectedUser.display)
              }
              onChange={(val) => setAssignee(val)}
              label="email@example.com"
              limit={15}
              disabled={false}
              allowFreeform
            />
          </FormItem>
        </Form>
      </div>
    </Modal>
  );
};

export default AddSurveyDialog;
