import React from 'react';
import { useSelector } from 'react-redux';
import { Card } from 'antd';

import colors from '@totem/styles/colors';
import { ReduxStore, VersionsState } from '@totem/types/store';

import GenericCardTitle from './GenericCardTitle';

import './versions.css';

const styles = {
  cardStyle: {
    padding: 0,
    width: '100%',
    color: colors.neutral.white,
  },
  cardHeader: {
    padding: '0',
  },
  cardBody: {
    padding: '1.5rem',
  },
};

const VersionsTile = () => {
  const versions = useSelector<ReduxStore, VersionsState>(
    state => state.versions,
  );

  return (
    <div styleName="versions-card">
      <Card
        title={<GenericCardTitle title="Application Version" />}
        style={styles.cardStyle}
        styles={{body: styles.cardBody, header: styles.cardHeader}}
        bordered={false}
      >
        <div styleName="version-row">
          <div styleName="version-label">Web Application:</div>
          <div styleName="version-value">{versions.web || '-'}</div>
        </div>
        <div styleName="version-row">
          <div styleName="version-label">Backend Infrastructure:</div>
          <div styleName="version-value">{versions.server || '-'}</div>
        </div>
      </Card>
    </div>
  );
};

export default VersionsTile;
