import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { Tabs, TabsProps } from 'antd';
import * as R from 'ramda';

import DeviceTable from '@totem/components/buildingDetail/DeviceTable';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import RemoteAccessRoles from '@totem/components/common/remoteAccess/panel/RemoteAccessRoles';
import ComponentHeader from '@totem/components/ComponentHeader';
import ComplianceSelector from '@totem/components/controlSystemDetail/ComplianceSelector';
import ControlSystemContainer from '@totem/components/controlSystemDetail/container/controlSystemContainer';
import DeviceBackupPanel from '@totem/components/deviceBackups/DeviceBackupPanel';
import DeviceContainer from '@totem/components/devices/devicesContainer/DeviceContainer';
import EventFilterContainer from '@totem/components/events/eventFilters/EventFilterContainer';
import EventContainerTable from '@totem/components/events/eventsContainer/EventContainerTable';
import EventEmbeddedContainer from '@totem/components/events/eventsContainer/EventEmbeddedContainer';
import PageSection from '@totem/components/PageSection';
import RecordCountBadge from '@totem/components/RecordCountBadge';
import ComplianceSummaryContainer from '@totem/components/surveyV2/complianceSummary/complianceSummaryContainer';
import PolicyAuditReportContainer from '@totem/components/surveyV2/policyAuditReport/policyAuditReportContainer';
import ReportTable from '@totem/components/surveyV2/policyAuditReport/ReportTable';
import TabTitle from '@totem/components/TabTitle';
import UserProfileContext from '@totem/components/UserProfileContext';
import { GET_CONTROL_SYSTEM } from '@totem/graph/controlSystem';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import { APIKeysConnectionInput } from '@totem/types/apiKey';
import { ControlSystem } from '@totem/types/controlSystem';
import { ControlSystemBackupsConnectionInput } from '@totem/types/controlSystemBackup';
import {
  hasAnyActiveService,
  isMemberOfAny,
} from '@totem/utilities/userUtilities';

import ContentLayout from '../ContentLayout';

import ControlSystemSidebar from './controlSystemSidebar/ControlSystemSidebar';
import ControlSystemDetailContext from './ControlSystemDetailContext';

import './controlSystemDetail.css';

const ControlSystemDetail = () => {
  const { id } = useParams();
  const { userProfile } = useContext(UserProfileContext);
  const [deviceTotal, setDeviceTotal] = useState<number>(0);
  const [eventTotal, setEventTotal] = useState<number>(0);

  const { remoteAccess: showRemoteAccess } =
    userProfile.organization?.preferences?.features;

  const [apiKeysInput, setApiKeysInput] = useState<APIKeysConnectionInput>({
    limit: 10,
    offset: 0,
  });

  const [backupsInput, setBackupsInput] =
    useState<ControlSystemBackupsConnectionInput>({
      limit: 10,
      offset: 0,
    });

  const { data, loading, error, refetch } = useQuery<{
    controlSystem: ControlSystem;
  }>(GET_CONTROL_SYSTEM, {
    variables: {
      id,
      apiKeysConnectionInput: apiKeysInput,
      controlSystemBackupsConnectionInput: backupsInput,
      notesConnectionInput: {},
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const canSeeAudits = userProfile.organization.role >= 3;
  const controlSystem = R.pathOr<ControlSystem>(null, ['controlSystem'], data);

  useErrorNotification(error, 'Error Retrieving Control System');

  if ((loading && !controlSystem) || error) {
    return (
      <div styleName="spinner-container">
        <DetailSpinner />
      </div>
    );
  }

  const userCanSeeRemoteAccess = isMemberOfAny(
    userProfile,
    [
      'remote_access_viewer',
      'remote_access_admin',
      'remote_access_configuration_admin',
    ],
    {
      regionId: controlSystem.region.id,
      buildingId: controlSystem.building.id,
      controlSystemId: controlSystem.id,
    },
  );

  const userCanSeeEvents = hasAnyActiveService(userProfile, [
    'threat_management',
  ]);

  const getTabItems = () => {
    const tabItems: TabsProps['items'] = [
      {
        key: '1',
        label: <TabTitle>Compliance</TabTitle>,
        children: (
          <PageSection>
            <ComplianceSummaryContainer controlSystemId={[controlSystem.id]}>
              <ComplianceSelector />
            </ComplianceSummaryContainer>
            {/*<ControlSystemAuditsContainer controlSystemId={controlSystem.id}>*/}
            {/*  <SystemSecuritySelector*/}
            {/*    controlSystem={controlSystem}*/}
            {/*    starterModalOpen={starterModalOpen}*/}
            {/*    setStarterModalOpen={setStarterModalOpen}*/}
            {/*  />*/}
            {/*</ControlSystemAuditsContainer>*/}
          </PageSection>
        ),
      },
      {
        key: '4',
        label: <TabTitle>Continuity</TabTitle>,
        children: (
          <PageSection>
            <DeviceBackupPanel
              pRegionId={[]}
              pBuildingId={[]}
              pControlSystemId={[controlSystem.id]}
              pDeviceId={[]}
            />
          </PageSection>
        ),
      },
      {
        key: 'devices',
        label: <TabTitle>Devices</TabTitle>,
        children: (
          <PageSection>
            <DeviceContainer
              staticFilters={{
                controlSystemId: [data.controlSystem.id],
              }}
              onRecordTotalChanged={(total) => setDeviceTotal(total)}
            >
              <ComponentHeader
                title={'Devices'}
                options={<RecordCountBadge count={deviceTotal} />}
              />
              <DeviceTable />
            </DeviceContainer>
          </PageSection>
        ),
      },
    ];

    if (canSeeAudits) {
      tabItems.push({
        key: 'audits',
        label: <TabTitle>Audits</TabTitle>,
        children: (
          <PageSection>
            <PolicyAuditReportContainer
              staticFilters={{ controlSystemId: [data.controlSystem.id] }}
            >
              <ReportTable />
            </PolicyAuditReportContainer>
          </PageSection>
        ),
      });
    }

    if (userCanSeeEvents) {
      tabItems.push({
        key: '2',
        label: <TabTitle>Events</TabTitle>,
        children: (
          <PageSection>
            <EventFilterContainer
              staticFilters={{
                controlSystemId: [data.controlSystem.id],
                status: ['CREATED', 'ACKNOWLEDGED'],
              }}
            >
              <EventEmbeddedContainer
                onRecordTotalChanged={(total) => setEventTotal(total)}
              >
                <ComponentHeader
                  title={'Events'}
                  options={<RecordCountBadge count={eventTotal} />}
                />
                <EventContainerTable />
              </EventEmbeddedContainer>
            </EventFilterContainer>
          </PageSection>
        ),
      });
    }

    if (showRemoteAccess && userCanSeeRemoteAccess) {
      tabItems.push({
        key: '3',
        label: <TabTitle>Remote Access</TabTitle>,
        children: (
          <PageSection>
            <RemoteAccessRoles
              regionId={controlSystem.region.id}
              buildingId={controlSystem.building.id}
              buildingName={controlSystem.building.name}
              controlSystemId={controlSystem.id}
              controlSystemName={controlSystem.name}
            />
          </PageSection>
        ),
      });
    }

    return tabItems;
  };

  return (
    <ContentLayout
      pageTitle={controlSystem.name}
      contentStyle={{ padding: 0 }}
      pageSubtitle={[
        { label: controlSystem.organization.name },
        { label: controlSystem.region.name },
        {
          label: controlSystem.building.name,
          link: `/dashboard/buildings/${controlSystem.building.id}`,
        },
      ]}
      breadcrumbs={(breadcrumbs) => [
        ...breadcrumbs,
        {
          label: controlSystem.building.name,
          link: `/dashboard/buildings/${controlSystem.building.id}`,
        },
        {
          label: 'Control Systems',
          link: '/dashboard/controlSystemReport',
        },
        {
          label: 'System Details',
        },
      ]}
    >
      <ControlSystemDetailContext.Provider
        value={{
          controlSystem,
          refetch,
          loading,
          apiKeysInput,
          setApiKeysInput,
          backupsInput,
          setBackupsInput,
        }}
      >
        <ControlSystemContainer id={id} onDataRefreshRequested={() => {}}>
          <div styleName="content">
            <div styleName="base-pane">
              <ControlSystemSidebar />
              <div styleName="main">
                <Tabs defaultActiveKey="1" items={getTabItems()} />
              </div>
            </div>
          </div>
        </ControlSystemContainer>
      </ControlSystemDetailContext.Provider>
    </ContentLayout>
  );
};

export default ControlSystemDetail;
