import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Select } from 'antd';
import * as R from 'ramda';

import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { AddEditUser } from '@totem/types/user';
import accountUtilities, {
  getToken,
} from '@totem/utilities/accountUtilities';
import { validateEmail } from '@totem/utilities/validation';
import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import { isNotNull } from '@totem/utilities/common';
import { RequestUserCreate } from '@totem/components/users/types';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import UserProfileContext from '@totem/components/UserProfileContext';

const FormItem = Form.Item;
const { Option } = Select;

interface FormErrors {
  email?: string;
  firstName?: string;
  lastName?: string;
}

export interface Props {
  visible: boolean;
  loading: boolean;
  onClose: () => void;
}

const initialUser: AddEditUser = {
  role: 0,
  email: '',
  firstName: '',
  lastName: '',
};

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

export const isValidForm = (user: AddEditUser): FormErrors => {
  const formErrors: FormErrors = {};

  if (R.isEmpty(user.email)) {
    formErrors.email = 'Please enter an email';
  }

  if (user.email && !validateEmail(user.email)) {
    formErrors.email = 'Please enter a valid email';
  }

  if (R.isEmpty(user.firstName)) {
    formErrors.firstName = 'Please enter a first name';
  }

  if (R.isEmpty(user.lastName)) {
    formErrors.lastName = 'Please enter a last name';
  }

  return formErrors;
};

const UserCreateModal = (props: Props) => {
  const { visible, loading, onClose } = props;

  const [user, setUser] = useState<AddEditUser>(initialUser);
  const [errors, setErrors] = useState<FormErrors>({});
  const { userProfile } = useContext(UserProfileContext);
  const [sending, setSending] = useState(false);

  useEffect(() => {
    if (!visible) {
      setErrors({});
      setUser(initialUser);
    }
  }, [visible]);

  const sendUserCreate = useCallback(async (request: RequestUserCreate) => {
    if (!sending) {
      setSending(true);
      fetch(`${USERS_ENDPOINT}/create`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then(res => {
          setSending(false);
          CheckResponseShowError(res);
          onClose();
        });
    }
  }, [])

  const handleChange = ({
    target: { value, name },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [name]: value });
  };

  const handleSubmit = () => {
    const formErrors = isValidForm(user);

    if (R.isEmpty(formErrors)) {
      const payload: RequestUserCreate = {
        organizationId: userProfile.organization.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        role: user.role,
        sendNotifications: true,
      };
      sendUserCreate(payload);
    }

    setErrors(formErrors);
  };

  const handleEmailChange = (email: string) => {
    setUser({ ...user, email });
  };

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={<ModalTitle>Add User</ModalTitle>}
      okText="Add"
      confirmLoading={loading}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleSubmit} loading={loading} type="primary">
            Add
          </Button>
        </ModalFooter>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem label="Role" colon={false} style={styles.formItem}>
          <Select
            onChange={(role) => setUser({ ...user, role })}
            value={user.role}
          >
            {accountUtilities.USER_ROLES.filter(
              // TODO: Remove @ts-ignore : Token
              // @ts-ignore
              (role) => role.value <= userProfile.role,
            ).map((role) => (
              <Option key={role.value} value={role.value}>
                {role.label}
              </Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          label="Email"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.email) ? 'success' : 'error'}
          help={R.isNil(errors.email) ? null : errors.email}
        >
          <GenericAutoComplete
            type={'User'}
            label={''}
            limit={15}
            onSelect={(value) => handleEmailChange(value.display)}
            onChange={handleEmailChange}
            allowClear={true}
            allowFreeform={false}
            selectedValue={isNotNull(user) ? {display: user.email, value: user.id} : {display: '', value: ''}}
          />
        </FormItem>
        <FormItem
          label="First Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.firstName) ? 'success' : 'error'}
          help={R.isNil(errors.firstName) ? null : errors.firstName}
        >
          <Input
            name="firstName"
            value={user.firstName}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
        <FormItem
          label="Last Name"
          colon={false}
          style={styles.formItem}
          validateStatus={R.isNil(errors.lastName) ? 'success' : 'error'}
          help={R.isNil(errors.lastName) ? null : errors.lastName}
        >
          <Input
            name="lastName"
            value={user.lastName}
            onChange={handleChange}
            type="search"
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default UserCreateModal;
