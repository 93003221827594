import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CloseCircleFilled } from '@ant-design/icons';
import { Button, Checkbox, Input, notification } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { WorkItem } from '@totem/components/surveyV2/work/types';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import { User } from '@totem/components/users/container/types';
import colors from '@totem/styles/colors';
import { getToken } from '@totem/utilities/accountUtilities';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';
import UserAssignedWork from '@totem/components/users/UserAssignedWork';

import '../../../users.css';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import UserProfileContext from '@totem/components/UserProfileContext';
import { isNotNull } from '@totem/utilities/common';

interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
}

const getMessage = (
  email: string,
  hasWork: boolean,
  removeEverywhere: boolean,
) => {
  if (removeEverywhere) {
    return `Are you sure you want to remove ${email} from all organizations, queues, contacts & remote access?  This cannot be undone.`;
  }
  if (hasWork) {
    return `Are you sure you want to remove site access for ${email}? This user has been assigned work and their email will still be listed as an assignee. If you remove this user, their site access will be revoked and they will no longer be able to access work from your organization.`;
  }

  return `Are you sure you want to remove ${email} from your site? They will no longer have access to your organization.`;
};

const styles = {
  button: {
    background: colors.utility.error,
    color: colors.neutral.white,
  },
  errorIcon: {
    color: colors.utility.error,
    marginRight: '1rem',
    fontSize: '22px',
  },
};

const UserRemoveIBModal = ({ open, onClose, user: { id, email } }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [sending, setSending] = useState(false);
  const [deactivatedFor, setDeactivatedFor] = useState<string>('');
  const [removeEverywhere, setRemoveEverywhere] = useState<boolean>(false);
  const [submitRemoveEverywhere, setSubmitRemoveEverywhere] =
    useState<boolean>(false);
  const [assignedWork, setAssignedWork] = useState<WorkItem[]>([]);

  useEffect(() => {
    if (open) {
      fetch(`${USERS_ENDPOINT}/${id}/work`, {
        method: 'GET',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      })
        .then(res => {
          CheckResponseShowError(res);
          return res.json();
        })
        .then((result: WorkItem[]) => {
          setAssignedWork(result);
        });
    } else {
      setDeactivatedFor('');
    }
  }, [id, open]);

  const sendDeactivateUser = useCallback(async (userId: string, reason: string) => {
    if (!sending) {
      setSending(true);

      const payload = {
        reason: reason,
      }

      fetch(`${USERS_ENDPOINT}/${userId}/deactivate`, {
        method: 'PATCH',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then(res => {
          setSending(false);
          CheckResponseShowError(res);
          onClose();
        });
    }
  }, []);

  useEffect(() => {
    if (submitRemoveEverywhere === true) {
      setSubmitRemoveEverywhere(false);
      const endpoint = `${USERS_ENDPOINT}/purge/${id}`;

      fetch(`${endpoint}`, {
        method: 'DELETE',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
      }).then((res) => {
        if (res.status < 400) {
          setSubmitRemoveEverywhere(false);
          onClose();
        } else {
          notification.error({
            message: 'Error removing user!',
            description: 'An error occurred while removing the user.',
            duration: 0,
          });
          setSubmitRemoveEverywhere(false);
        }
      });
    }
  }, [submitRemoveEverywhere, id]);

  const handleSubmit = async () => {
    sendDeactivateUser(id, deactivatedFor);
  };

  const handleSubmitRemoveEverywhere = () => {
    setSubmitRemoveEverywhere(true);
  };

  const userHasWork = isNotNull(assignedWork) && assignedWork.length > 0;

  return (
    <Modal
      title={
        <ModalTitle>
          <CloseCircleFilled style={styles.errorIcon} />
          Remove User
        </ModalTitle>
      }
      onCancel={onClose}
      open={open}
      footer={
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            style={styles.button}
            loading={sending}
            onClick={
              removeEverywhere ? handleSubmitRemoveEverywhere : handleSubmit
            }
            disabled={!removeEverywhere && !deactivatedFor && userProfile.organization.role < 2}
          >
            Remove
          </Button>
        </ModalFooter>
      }
    >
      {sending ? (
        <div styleName="modal-spinner-container">
          <DetailSpinner color={colors.utility.error} />
        </div>
      ) : (
        <>
          <div>
            <Checkbox
              onChange={() => setRemoveEverywhere(!removeEverywhere)}
              checked={removeEverywhere}
            >
              Remove user from all organizations
            </Checkbox>
          </div>
          <div>{getMessage(email, !!userHasWork, removeEverywhere)}</div>
          {!removeEverywhere && (
            <div styleName="modal-input">
              <div>Why is this user being removed?</div>
              <Input.TextArea
                value={deactivatedFor}
                onChange={(event) => setDeactivatedFor(event.target.value)}
                rows={4}
              />
            </div>
          )}
          {!!userHasWork && <UserAssignedWork userAssignedWork={assignedWork} />}
        </>
      )}
    </Modal>
  );
};

export default UserRemoveIBModal;
