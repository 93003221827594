import React from 'react';
import { RuleCondition, RuleGroup } from '@totem/components/rulesEngine/types';
import { Flex } from 'antd';
import RuleConditionDisplay from '@totem/components/rulesEngine/report/ruleConditionDisplay';
import { isNotNull } from '@totem/utilities/common';

const styles = {
  groupOperator: {
    borderRightStyle: 'solid' as 'solid',
    borderRightWidth: '2px',
    borderRightColor: '#ccc',
    paddingRight: '5px',
  }
}

type Props = {
  group: RuleGroup;
}

const RuleGroupDisplay = ({ group }: Props) => {
  return (
    <Flex justify={'flex-start'} gap={'small'}>
      <div style={styles.groupOperator}>{group.operator}</div>
      <div>
        {isNotNull(group.conditions) && group.conditions.map((condition: RuleCondition, idx: number) => idx===0 ? <RuleConditionDisplay key={condition.id} condition={condition} /> : <><br /><RuleConditionDisplay key={condition.id} condition={condition} /></>)}
        {isNotNull(group.groups) && group.groups.map((group: RuleGroup, idx: number) => idx===0 ? <RuleGroupDisplay key={group.id} group={group} /> : <><br /><RuleGroupDisplay key={group.id} group={group} /></>)}
      </div>
    </Flex>
  );
};

export default RuleGroupDisplay;
