import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import Table from '@totem/components/common/table/Table';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { isManaged } from '@totem/components/devices/devicesContainer/utilities';
import UserProfileContext from '@totem/components/UserProfileContext';
import { DeviceQueryResult } from '@totem/types/devices';
import { getTypeFilter, getVendorFilter } from '@totem/utilities/devices';
import {
  nullifyIfEmpty,
  stringifyArray,
} from '@totem/utilities/tableFilterUtilities';
import { getFilterOptions } from '@totem/utilities/ticketing';
import { getUserRole } from '@totem/utilities/userUtilities';

const BackupNotOnContractTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { input, setInput, loading, deviceData, filterOptions, totalRecords } =
    useContext(DeviceContext);

  const isSysAdmin = getUserRole(userProfile) === 3;

  const getBuildingDisplay = (device: DeviceQueryResult) => {
    if (
      device.building !== null &&
      device.building.id !== null &&
      device.building.id !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/buildings/${device.building.id}`}>
          {device.building.name}
        </Link>
      );
    } else if (device.building !== null) {
      return <span>{device.building.name}</span>;
    }
    return <span>-</span>;
  };

  const getControlSystemDisplay = (device: DeviceQueryResult) => {
    if (
      device.controlSystem !== null &&
      device.controlSystem.id !== null &&
      device.controlSystem.id !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/controlsystems/${device.controlSystem.id}`}>
          {device.controlSystem.name}
        </Link>
      );
    } else if (device.controlSystem !== null) {
      return <span>{device.controlSystem.name}</span>;
    }
    return <span>-</span>;
  };

  const columns: ColumnProps<DeviceQueryResult>[] = [
    {
      title: 'Name',
      dataIndex: 'device.displayName',
      key: 'displayName',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => (
        <Link to={`/dashboard/devices/${device.device.id}`}>
          {device.device.displayName}
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.device.displayName === compB.device.displayName
          ? 0
          : compA.device.displayName > compB.device.displayName
            ? 1
            : -1,
    },
    {
      title: 'Type',
      dataIndex: 'device.type',
      key: 'type',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.device.type,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.device.type === compB.device.type
          ? 0
          : compA.device.type > compB.device.type
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.type),
      filters: getTypeFilter(filterOptions),
    },
    {
      title: 'Vendor',
      dataIndex: 'device.vendor',
      key: 'vendor',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.device.vendor,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.device.vendor === compB.device.vendor
          ? 0
          : compA.device.vendor > compB.device.vendor
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.vendor),
      filters: getVendorFilter(filterOptions),
    },
    // {
    //   title: 'OS',
    //   dataIndex: 'device.operatingSystem',
    //   key: 'operatingSystem',
    //   showSorterTooltip: true,
    //   render: (_, device: DeviceQueryResult) => device.device.operatingSystem,
    //   sortDirections: ['ascend', 'descend'],
    //   sorter: (compA, compB) =>
    //     compA.device.operatingSystem === compB.device.operatingSystem
    //       ? 0
    //       : compA.device.operatingSystem > compB.device.operatingSystem
    //         ? 1
    //         : -1,
    //   filterMultiple: true,
    //   filteredValue: stringifyArray(input.operatingSystem),
    //   filters: getOperatingSystemFilter(filterOptions),
    // },
    {
      title: 'IP Addresses',
      dataIndex: 'device.ipAddresses',
      key: 'ipAddresses',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => (
        <span>
          {device.device.ipAddresses !== null &&
            device.device.ipAddresses.map((addr, idx) =>
              idx === 0 ? (
                <span key={addr}>{addr}</span>
              ) : (
                <span key={addr}>
                  <br />
                  {addr}
                </span>
              ),
            )}
        </span>
      ),
    },
    {
      title: 'Organization',
      dataIndex: 'organization.name',
      key: 'organizationId',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.organization.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.organization.name === compB.organization.name
          ? 0
          : compA.organization.name > compB.organization.name
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.organizationId),
      filters: userProfile.organizations.map((org) => {
        return {
          text: org.name,
          value: org.id,
        };
      }),
    },
    {
      title: 'Region',
      dataIndex: 'region.name',
      key: 'regionId',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.region.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.region.name === compB.region.name
          ? 0
          : compA.region.name > compB.region.name
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.regionId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.region
          : null,
      ),
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'buildingId',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => getBuildingDisplay(device),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.building.name === compB.building.name
          ? 0
          : compA.building.name > compB.building.name
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.building
          : null,
      ),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystem.name',
      key: 'controlSystemId',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => getControlSystemDisplay(device),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.controlSystem.name === compB.controlSystem.name
          ? 0
          : compA.controlSystem.name > compB.controlSystem.name
            ? 1
            : -1,
    },
  ];

  const getColumns = () => {
    const cols = [...columns];

    if (isSysAdmin) {
      cols.splice(4, 0, {
        title: 'Managed',
        dataIndex: 'device.flags.managed.status',
        key: 'isManaged',
        showSorterTooltip: true,
        render: (_, device: DeviceQueryResult) =>
          isManaged(device) ? (
            <CheckCircleOutlined
              style={{
                color: 'green',
                fontSize: '20px',
              }}
            />
          ) : (
            <MinusCircleOutlined
              style={{
                fontSize: '20px',
              }}
            />
          ),
        sortDirections: ['ascend', 'descend'],
        sorter: (compA, compB) =>
          compA.device.flags.managed.status ===
          compB.device.flags.managed.status
            ? 0
            : compA.device.flags.managed.status >
                compB.device.flags.managed.status
              ? 1
              : -1,
        filterMultiple: false,
        filteredValue: nullifyIfEmpty(input.isManaged),
        filters: [
          {
            text: (
              <span>
                <CheckCircleOutlined
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                &nbsp;Is Managed
              </span>
            ),
            value: 'ACTIVE',
          },
          {
            text: (
              <span>
                <MinusCircleOutlined
                  style={{
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                &nbsp;Not Managed
              </span>
            ),
            value: 'INACTIVE',
          },
        ],
      });
    }

    return cols;
  };

  const getDevices = () => {
    if (deviceData !== null) {
      if (deviceData.devices !== null) {
        return deviceData.devices;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<DeviceQueryResult>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={getColumns()}
      dataSource={getDevices()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={(record) => record.device.id}
    />
  );
};
export default BackupNotOnContractTable;
