import React from 'react';

import BasePane from '@totem/components/BasePane';
import ContentLayout from '@totem/components/ContentLayout';
import NetworkConcernsContainer from '@totem/components/networkConcerns/networkConcernsContainer';
import NetworkConcernsLayout from '@totem/components/networkConcerns/networkConcernsLayout';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';

const NetworkConcernsPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <NetworkConcernsContainer>
            <div style={{ padding: '16px' }}>
              <NetworkConcernsLayout />
            </div>
          </NetworkConcernsContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default NetworkConcernsPage;
