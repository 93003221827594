import React from 'react';

import SoftwareTable from '@totem/components/software/software_report/byTag/softwareTable';
import { SoftwarePackage } from '@totem/components/software/software_report/types';

type Props = {
  tag: string;
  packages: SoftwarePackage[];
};

const TagPanel = ({ packages }: Props) => {
  return (
    <SoftwareTable data={packages} />
  );
};

export default TagPanel;
