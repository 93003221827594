import { createContext } from 'react';

import {
  FilterOptions,
  Rule,
  RuleConnectionInput,
} from '@totem/components/rulesEngine/types';

interface Context {
  rulesData: Rule[];
  filterOptions: FilterOptions;
  loading: boolean;
  input: RuleConnectionInput;
  setInput: (input: RuleConnectionInput) => void;
}

export default createContext<Context>({
  loading: false,
  rulesData: null,
  filterOptions: { domain: [], trigger: [], action: [] },
  input: {},
  setInput: () => {},
});
