import React, { useCallback, useContext, useState } from 'react';
import { CloseSquareOutlined } from '@ant-design/icons';
import { Input } from 'antd';

import SoftwareContext from '@totem/components/software/software_report/softwareContext';
import { debounce } from '@totem/utilities/debounce';
import { SoftwareCatalogInput } from '@totem/components/software/software_report/types';
const DEBOUNCE_TIME = 300;
const SoftwareFilters = () => {
  const { setInput } = useContext(SoftwareContext);
  const [nameSearchTerm, setNameSearchTerm] = useState<string>('');
  const [publisherSearchTerm, setPublisherSearchTerm] = useState<string>('');
  const [tagSearchTerm, setTagSearchTerm] = useState<string>('');

  const updateName = useCallback(
    debounce(
      (newSearchTerm: string) => setInput({  name: newSearchTerm }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleNameSearch = (newNameSearch: string) => {
    setNameSearchTerm(newNameSearch);
    updateName(newNameSearch);
  };

  const updatePublisher = useCallback(
    debounce(
      (newSearchTerm: string) =>
        setInput({ publisher: newSearchTerm }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handlePublisherSearch = (newNameSearch: string) => {
    setPublisherSearchTerm(newNameSearch);
    updatePublisher(newNameSearch);
  };

  const updateTag = useCallback(
    debounce(
      (newSearchTerm: string) => setInput({ tag: newSearchTerm }),
      DEBOUNCE_TIME,
    ),
    [],
  );

  const handleTagSearch = (newNameSearch: string) => {
    setTagSearchTerm(newNameSearch);
    updateTag(newNameSearch);
  };

  return (
    <table style={{ width: '100%', marginLeft: '20px' }}>
      <tbody>
        <tr>
          <td style={{ width: '300px' }}>
            <b>Name:</b>&nbsp;
            <Input
              placeholder="Name Search"
              value={nameSearchTerm}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handleNameSearch(evt.target.value)
                  : handleNameSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handleNameSearch('')} />
          </td>
          <td style={{ width: '150px' }}>&nbsp;</td>
          <td style={{ width: '300px' }}>
            <b>Publisher:</b>&nbsp;
            <Input
              placeholder="Publisher Search"
              value={publisherSearchTerm}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handlePublisherSearch(evt.target.value)
                  : handlePublisherSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handlePublisherSearch('')} />
          </td>
          <td style={{ width: '150px' }}>&nbsp;</td>
          <td style={{ width: '300px' }}>
            <b>Tags:</b>&nbsp;
            <Input
              placeholder="Tag Search"
              value={tagSearchTerm}
              onChange={(evt: React.ChangeEvent<HTMLInputElement>) =>
                evt.target.value !== null
                  ? handleTagSearch(evt.target.value)
                  : handleTagSearch('')
              }
            />
          </td>
          <td>
            <br />
            <CloseSquareOutlined onClick={() => handleTagSearch('')} />
          </td>
          <td>&nbsp;</td>
        </tr>
      </tbody>
    </table>
  );
};

export default SoftwareFilters;
