import React, { useContext, useState } from 'react';
import RulesContext from '@totem/components/rulesEngine/report/rulesContext';

import { ColumnProps } from 'antd/lib/table';
import { Rule } from '@totem/components/rulesEngine/types';
import Table from '@totem/components/common/table/Table';
import { isNotNull } from '@totem/utilities/common';
import { sortStringAscending } from '@totem/utilities/tableUtilities';
import RuleGroupDisplay from '@totem/components/rulesEngine/report/ruleGroupDisplay';
import { TablePaginationConfig } from 'antd/es/table';
import { SorterResult } from 'antd/lib/table/interface';
import { stringifyArray } from '@totem/utilities/tableFilterUtilities';

const RulesTable = () => {
  const { rulesData, loading, input, setInput, filterOptions } = useContext(RulesContext);

  const columns: ColumnProps<Rule>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      showSorterTooltip: true,
      render: (_, record: Rule) => (
        <span>
          {record.name}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.name, compB.name),
    },
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      showSorterTooltip: true,
      render: (_, record: Rule) => (
        <span>
          {record.domain}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.domain, compB.domain),
      filterMultiple: true,
      filteredValue: stringifyArray(input.domain),
      filters: isNotNull(filterOptions) && isNotNull(filterOptions.domain) ? filterOptions.domain.map((item) => {
        return {
          text: item,
          value: item,
        }
      }) : [],
    },
    {
      title: 'Trigger',
      dataIndex: 'trigger',
      key: 'trigger',
      showSorterTooltip: true,
      render: (_, record: Rule) => (
        <span>
          {isNotNull(record.trigger) && record.trigger.map((val, idx) => {
            if (idx === 0) {
              return <span key={idx}>{val}</span>;
            }
            return <span key={idx}>, {val}</span>;
          })}
        </span>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.domain, compB.domain),
      filterMultiple: true,
      filteredValue: stringifyArray(input.trigger),
      filters: isNotNull(filterOptions) && isNotNull(filterOptions.trigger) ? filterOptions.trigger.map((item) => {
        return {
          text: item,
          value: item,
        }
      }) : [],
    },
    {
      title: 'Condition',
      dataIndex: 'group',
      key: 'group',
      showSorterTooltip: true,
      render: (_, record: Rule) => <RuleGroupDisplay group={record.group} />,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'action',
      render: (_, record: Rule) => (
        <span>
          {isNotNull(record.actions) && record.actions.map((val, idx) => {
            if (idx === 0) {
              return <span key={val.id} title={val.data}>{val.type}</span>;
            }
            return <span key={val.id} title={val.data}>, {val.type}</span>;
          })}
        </span>
      ),
      filterMultiple: true,
      filteredValue: stringifyArray(input.action),
      filters: isNotNull(filterOptions) && isNotNull(filterOptions.action) ? filterOptions.action.map((item) => {
        return {
          text: item,
          value: item,
        }
      }) : [],
    },
  ];

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<Rule>,
    sorter,
  ) => {
    const { ...params } = filters;

    // @ts-ignore
    setInput({
      ...input,
      ...params,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={columns}
      dataSource={isNotNull(rulesData) ? rulesData : []}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        total: isNotNull(rulesData) ? rulesData.length : 0,
        showSizeChanger: true,
      }}
      rowKey={(record) => record.id}
    />
  );
};

export default RulesTable;
