import { Health, HealthDTO, HealthsRequest } from '@totem/types/health';

import actionTypes from './actionTypes';

export const createHealthSuccess = (data: Health) => ({
  type: actionTypes.CREATE_HEALTH_SUCCESS,
  data,
});

export const createHealthFailure = () => ({
  type: actionTypes.CREATE_HEALTH_FAILURE,
});

export const getHealthSuccess = (data: Health[]) => ({
  type: actionTypes.GET_HEALTH_SUCCESS,
  data,
});

export const getHealthFailure = () => ({
  type: actionTypes.GET_HEALTH_FAILURE,
});
