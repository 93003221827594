import React from 'react';
import ContentLayout from '@totem/components/ContentLayout';
import BasePane from '@totem/components/BasePane';
import PrimaryContentPane from '@totem/components/PrimaryContentPane';
import RulesContainer from '@totem/components/rulesEngine/report/rulesContainer';
import RulesTable from '@totem/components/rulesEngine/report/rulesTable';

const RulesPage = () => {
  return (
    <ContentLayout>
      <BasePane>
        <PrimaryContentPane>
          <RulesContainer>
            <div style={{ padding: '16px' }}>
            <RulesTable />
            </div>
          </RulesContainer>
        </PrimaryContentPane>
      </BasePane>
    </ContentLayout>
  );
};

export default RulesPage;
