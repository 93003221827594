import React, { useContext } from 'react';

import DeviceInterfaceTable from '@totem/components/networkConcerns/deviceInterfaceTable';
import NetworkConcernsContext from '@totem/components/networkConcerns/networkConcernsContext';
import { isNotNull } from '@totem/utilities/common';

const NetworkConcernsLayout = () => {
  const { reportData } = useContext(NetworkConcernsContext);
  return (
    <div>
      <b>Multiple Adapters Active: </b><br />
      <DeviceInterfaceTable
        data={isNotNull(reportData) ? reportData.multipleAdapters : []}
      />
      <b>Wireless Adapters Active: </b><br />
      <DeviceInterfaceTable
        data={isNotNull(reportData) ? reportData.wirelessAdapters : []}
      />
    </div>
  );
};

export default NetworkConcernsLayout;
