import React from 'react';
import { Card, Col, Row } from 'antd';

import AuditReport from '@totem/components/surveyV2/auditReport/AuditReport';
import FindingsByCategory from '@totem/components/surveyV2/auditReport/FindingsByCategory';
import FindingsByCriticality from '@totem/components/surveyV2/auditReport/FindingsByCriticality';
import FindingsByLevelOfEffort from '@totem/components/surveyV2/auditReport/FindingsByLevelOfEffort';
import colors from '@totem/styles/colors';

import './auditReport.css';

const styles = {
  cardHeader: {
    borderTop: `5px solid`,
    borderBottom: `1px solid ${colors.antd.borderGray}`,
    borderImage: `${colors.gradient.blue} 100% 0 0 0`,
  },
  cardBody: {
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
};

const AuditFindingsOverview = () => {
  return (
    <Card
      bordered
      styles={{body: styles.cardBody, header: styles.cardHeader}}
      title={<div styleName="card-header-title">Audit Findings Overview</div>}
    >
      <Row gutter={24}>
        <Col span={8}>
          <FindingsByCategory />
        </Col>
        <Col span={8}>
          <FindingsByCriticality />
        </Col>
        <Col span={8}>
          <FindingsByLevelOfEffort />
        </Col>
      </Row>
      <AuditReport />
    </Card>
  );
};

export default AuditFindingsOverview;
