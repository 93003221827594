import React, { ReactNode, useEffect, useState } from 'react';

import NetworkConcernsContext from '@totem/components/networkConcerns/networkConcernsContext';
import { NetworkConcerns } from '@totem/components/networkConcerns/types';
import { getToken } from '@totem/utilities/accountUtilities';
import { DEVICES_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponse } from '@totem/utilities/responseUtilities';

type Props = {
  children?: ReactNode;
};

const NetworkConcernsContainer = ({ children }: Props) => {
  const [data, setData] = useState<NetworkConcerns>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    fetch(`${DEVICES_ENDPOINT}/network/interface/concerns`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${getToken()}`,
      }),
    })
      .then((res) => CheckResponse(res))
      .then((res) => res.json())
      .then((result: NetworkConcerns) => {
        setData(result);
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  return (
    <NetworkConcernsContext.Provider
      value={{
        reportData: data,
        loading,
      }}
    >
      <div>{children}</div>
    </NetworkConcernsContext.Provider>
  );
};

export default NetworkConcernsContainer;
