import React, { useContext } from 'react';
import { TagOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import SoftwareContext from '@totem/components/software/software_report/softwareContext';

const styles = {
  button: {
    marginRight: '20px',
  },
};

export interface Props {
  isPrimary?: boolean;
}
const BulkTagButton = ({ isPrimary }: Props) => {
  const { selectedPackages, action, onAction } = useContext(SoftwareContext);

  return (
    <Button
      style={styles.button}
      icon={<TagOutlined />}
      type={
        typeof isPrimary !== 'undefined' && isPrimary ? 'primary' : 'default'
      }
      disabled={
        action === 'bulk-tag' ||
        typeof selectedPackages === 'undefined' ||
        selectedPackages === null ||
        selectedPackages.length < 1
      }
      onClick={() => onAction('bulk-tag', null)}
    >
      Bulk Tag
    </Button>
  );
};

export default BulkTagButton;
