import actionTypes from './actionTypes';

export const uploadAdditionalDeviceConfigurationSuccess = data => ({
  type: actionTypes.UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION_SUCCESS,
  data,
});

export const uploadAdditionalDeviceConfigurationFailure = () => ({
  type: actionTypes.UPLOAD_ADDITIONAL_DEVICE_CONFIGURATION_FAILURE,
});
