import React, { useCallback, useContext, useState } from 'react';
import { Card, Input } from 'antd';
import dayjs from 'dayjs';

import UserAvatar from '@totem/components/common/avatar/UserAvatar';
import DetailSpinner from '@totem/components/common/DetailSpinner';
import Multifactor from '@totem/components/settings/sections/profile/Multifactor';
import UserProfileContext from '@totem/components/UserProfileContext';
import colors from '@totem/styles/colors';
import { userRoleString, userRoleStyle } from '@totem/utilities/userUtilities';

import VersionsTile from '../../VersionsTile';

import ProfileTitleHeader from './ProfileTitleHeader';

import './profile.css';
import { RequestProfileUpdate } from '@totem/components/settings/sections/profile/types';
import { USERS_ENDPOINT } from '@totem/utilities/endpoints';
import { getToken } from '@totem/utilities/accountUtilities';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';

const styles = {
  form: {
    width: '100%',
    paddingLeft: '2.75rem',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  avatar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '3rem',
    height: '72px',
    width: '72px',
  },
  cardStyle: {
    width: '100%',
    color: colors.neutral.white,
  },
  cardHeader: {
    padding: '0',
  },
  cardBody: {
    padding: '1.5rem',
  },
};

interface EditableUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
}

export const Profile = () => {
  const { userProfile } = useContext(UserProfileContext);
  const [sending, setSending] = useState(false);

  const [user, setUser] = useState<EditableUser>({
    id: userProfile.id,
    email: userProfile.email,
    firstName: userProfile.firstName,
    lastName: userProfile.lastName,
    phoneNumber: userProfile.phoneNumber,
  });

  const [editMode, setEditMode] = useState<boolean>(false);

  const sendProfileUpdate = useCallback((request: RequestProfileUpdate) => {
    if (!sending) {
      setSending(true);
      fetch(`${USERS_ENDPOINT}/${request.userId}`, {
        method: 'PUT',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(request),
      })
        .then(res => {
          setSending(false);
          CheckResponseShowError(res);
          setEditMode(false);
        });
    }
  }, []);

  const onProfileSave = () => {
    const payload: RequestProfileUpdate = {
      userId: user.id,
      lastName: user.lastName,
      firstName: user.firstName,
      phoneNumber: user.phoneNumber,
    }
    sendProfileUpdate(payload);
  };
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const toggleEditMode = () => {
    if (editMode) {
      setUser({
        id: userProfile.id,
        email: userProfile.email,
        firstName: userProfile.firstName,
        lastName: userProfile.lastName,
        phoneNumber: userProfile.phoneNumber,
      });
    }

    setEditMode(!editMode);
  };

  const getProfileAttributeContent = () => {
    return editMode ? (
      <div styleName="attribute-list-container">
        <div styleName="attribute-list-row">
          <div styleName="attribute-label edit">Email Address:</div>
          <div styleName="attribute-value">
            <Input
              disabled
              autoComplete="off"
              value={user.email}
              name="email"
            />
          </div>
        </div>
        <div styleName="attribute-list-row">
          <div styleName="attribute-label edit">First Name:</div>
          <div styleName="attribute-value">
            <Input
              value={user.firstName}
              autoComplete="off"
              name="firstName"
              onChange={(event) => onChange(event)}
            />
          </div>
        </div>
        <div styleName="attribute-list-row">
          <div styleName="attribute-label edit">Last Name:</div>
          <div styleName="attribute-value">
            <Input
              value={user.lastName}
              autoComplete="off"
              name="lastName"
              onChange={(event) => onChange(event)}
            />
          </div>
        </div>
        <div styleName="attribute-list-row">
          <div styleName="attribute-label edit">Phone Number:</div>
          <div styleName="attribute-value">
            <Input
              value={user.phoneNumber}
              autoComplete="off"
              name="phoneNumber"
              onChange={(event) => onChange(event)}
            />
          </div>
        </div>
      </div>
    ) : (
      <div styleName="attribute-list-container">
        <div styleName="attribute-list-row">
          <div styleName="attribute-label">Email Address:</div>
          <div styleName="attribute-value">{userProfile.email || '-'}</div>
        </div>
        <div styleName="attribute-list-row">
          <div styleName="attribute-label">First Name:</div>
          <div styleName="attribute-value">{userProfile.firstName || '-'}</div>
        </div>
        <div styleName="attribute-list-row">
          <div styleName="attribute-label">Last Name:</div>
          <div styleName="attribute-value">{userProfile.lastName || '-'}</div>
        </div>
        <div styleName="attribute-list-row">
          <div styleName="attribute-label">Phone Number:</div>
          <div styleName="attribute-value">
            {userProfile.phoneNumber || '-'}
          </div>
        </div>
      </div>
    );
  };

  const roleString = userRoleString(userProfile.organization.role);
  const roleStyle = userRoleStyle(userProfile.organization.role);

  const content = sending ? (
    <div styleName="spinner-container">
      <DetailSpinner />
    </div>
  ) : (
    <div styleName="detail-container">
      <div styleName="profile-card">
        <Multifactor userID={userProfile.id} />
      </div>
      <div styleName="profile-card">
        <Card
          title={
            <ProfileTitleHeader
              isEdit={editMode}
              onSave={onProfileSave}
              loading={sending}
              toggleProfileEditing={toggleEditMode}
            />
          }
          style={styles.cardStyle}
          styles={{body: styles.cardBody, header: styles.cardHeader}}
          bordered={false}
        >
          {getProfileAttributeContent()}
        </Card>
      </div>
    </div>
  );

  return (
    <div>
      <div styleName="profile-image-container">
        <div styleName="avatar">
          <UserAvatar user={userProfile} style={styles.avatar} />
        </div>
        <div styleName="profile-image-content">
          <div styleName="profile-image-name">
            {`${userProfile.firstName} ${userProfile.lastName}`}
          </div>
          <div style={roleStyle}>{roleString}</div>
          <div styleName="profile-image-date">
            {`Join Date: ${dayjs(userProfile.createdAt).format('MM/DD/YYYY')}`}
          </div>
        </div>
      </div>
      <div>{content}</div>
      <VersionsTile />
    </div>
  );
};
