import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, Modal } from 'antd';
import * as R from 'ramda';

import SurveyInstanceContext from '@totem/components/surveyV2/instances/SurveyInstancesContext';
import fonts from '@totem/styles/fonts';
import { getToken } from '@totem/utilities/accountUtilities';
import { V2_SURVEY_ENDPOINT } from '@totem/utilities/endpoints';
import { CheckResponseShowError } from '@totem/utilities/responseUtilities';
import { validateEmail } from '@totem/utilities/validation';
import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import { isNotNull } from '@totem/utilities/common';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
const FormItem = Form.Item;

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '2rem',
  },
  modalHeader: {
    fontWeight: 700,
    fontSize: fonts.fontXl,
  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

interface FormErrors {
  assignee?: string;
}

const AssigneeDialog = () => {
  const { loading, selectedInstance, onAction, action } = useContext(
    SurveyInstanceContext,
  );
  const { survey } = selectedInstance;
  const [value, setValue] = useState<string>(
    selectedInstance.assignedUser.email,
  );
  const [errors, setErrors] = useState<FormErrors>({});
  const [isAssigneeSending, setIsAssigneeSending] = useState<boolean>(false);

  useEffect(() => {
    if (!open) {
      setErrors({});
      setValue('');
    } else {
      setValue(selectedInstance.assignedUser.email);
    }
  }, [open, selectedInstance.assignedUser.email]);

  const sendSetAssignee = useCallback(
    async (surveyId: string, assigneeEmail: string) => {
      if (isAssigneeSending) {
        return;
      }
      setIsAssigneeSending(true);

      const payload = {
        assignee: assigneeEmail,
        dueDate: 0,
      };

      fetch(`${V2_SURVEY_ENDPOINT}/instance/${surveyId}/assignee`, {
        method: 'POST',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(payload),
      })
        .then((res) => {
          CheckResponseShowError(res);
        })
        .then(() => {
          setIsAssigneeSending(false);
          onAction('refresh', null);
        });
    },
    [],
  );

  const isValid = (): boolean => {
    const errs: FormErrors = {};

    if (value && !validateEmail(value)) {
      errs.assignee = 'Please input a valid email';
    }

    setErrors(errs);
    return R.isEmpty(errs);
  };
  const handleSubmit = () => {
    if (isValid()) {
      sendSetAssignee(survey.id, value);
    }
  };

  return (
    <Modal
      open={action === 'show_assignment_dialog'}
      title={<div style={styles.modalHeader}>Update Assignee</div>}
      onCancel={() => onAction('', null)}
      footer={
        <div style={styles.modalFooter}>
          <Button onClick={() => onAction('', null)}>Cancel</Button>
          <Button type="primary" loading={loading} onClick={handleSubmit}>
            Save
          </Button>
        </div>
      }
    >
      <Form layout="vertical" style={styles.form}>
        <FormItem
          label="Assignee Email"
          colon={false}
          validateStatus={R.isNil(errors.assignee) ? 'success' : 'error'}
          help={R.isNil(errors.assignee) ? null : errors.assignee}
          style={styles.formItem}
        >
          <GenericAutoComplete
            type={'User'}
            label={''}
            limit={15}
            onSelect={(value) => setValue(value.display)}
            onChange={setValue}
            allowClear={true}
            allowFreeform={false}
            selectedValue={isNotNull(value) ? {display: value, value: EMPTY_ID} : {display: '', value: ''}}
          />
        </FormItem>
      </Form>
    </Modal>
  );
};

export default AssigneeDialog;
