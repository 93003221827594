import React from 'react';

import AssignedContentCollapse from '@totem/components/common/assignedContentCollapse/AssignedContentCollapse';
import { WorkItem } from '@totem/components/surveyV2/work/types';

import './users.css';
import { isNotNull } from '@totem/utilities/common';

export interface Props {
  userAssignedWork: WorkItem[];
}

const UserAssignedWork = ({ userAssignedWork }: Props) => {
  const assignedAuditsTotalCount =
    isNotNull(userAssignedWork) ?
    userAssignedWork.filter((chk) => chk.type === 'policyAudit').length : 0

  const assignedSurveysTotalCount =
    isNotNull(userAssignedWork) ?
      userAssignedWork.filter((chk) => chk.type === 'survey').length : 0

  const policyAudits = isNotNull(userAssignedWork) ?
    userAssignedWork.filter((chk) => chk.type === 'policyAudit').map(
    (policyAudit: WorkItem, index: number) => {
      return (
        <div key={index}>
          <div styleName="assigned-work-subtitle">
            {policyAudit.controlSystem.name}
          </div>
          <div>{policyAudit.building.name}</div>
        </div>
      );
    },
  ) : <span />;

  const questionnaireInstances =isNotNull(userAssignedWork) ?
    userAssignedWork.filter((chk) => chk.type === 'survey').map(
      (questionnaireInstance: WorkItem, index: number) => {
        return (
          <div key={index}>
            <div styleName="assigned-work-subtitle">
              {questionnaireInstance.name}
            </div>
            <div>{questionnaireInstance.building.name}</div>
          </div>
        );
      },
    ) : <span />;

  const assignedContents = [
    {
      disabled: !assignedAuditsTotalCount,
      header: `${assignedAuditsTotalCount} Assigned Audits`,
      details: policyAudits,
    },
    {
      disabled: !assignedSurveysTotalCount,
      header: `${assignedSurveysTotalCount} Assigned Surveys`,
      details: questionnaireInstances,
    },
  ];

  return (
    <>
      <div styleName="assigned-work-title">Work Assigned to this User</div>
      <div>
        <AssignedContentCollapse assignedContents={assignedContents} />
      </div>
    </>
  );
};

export default UserAssignedWork;
