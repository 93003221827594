import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import Table from '@totem/components/common/table/Table';
import DeviceActionMenu from '@totem/components/devices/DeviceActionMenu';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { isManaged } from '@totem/components/devices/devicesContainer/utilities';
import UserProfileContext from '@totem/components/UserProfileContext';
import { DeviceQueryResult } from '@totem/types/devices';
import {
  getOperatingSystemFilter,
  getTypeFilter,
  getVendorFilter,
} from '@totem/utilities/devices';
import {
  nullifyIfEmpty,
  stringifyArray,
} from '@totem/utilities/tableFilterUtilities';
import { getUserRole } from '@totem/utilities/userUtilities';

import '../devices/devices.css';

const DeviceTable = () => {
  const { userProfile } = useContext(UserProfileContext);
  const { input, setInput, loading, deviceData, filterOptions, totalRecords } =
    useContext(DeviceContext);

  const isSysAdmin = getUserRole(userProfile) === 3;

  const columns: ColumnProps<DeviceQueryResult>[] = [
    {
      title: 'Name',
      dataIndex: 'device.displayName',
      key: 'displayName',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => (
        <Link to={`/dashboard/devices/${device.device.id}`}>
          {device.device.displayName}
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.device.displayName === compB.device.displayName
          ? 0
          : compA.device.displayName > compB.device.displayName
            ? 1
            : -1,
    },
    {
      title: 'Type',
      dataIndex: 'device.type',
      key: 'type',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.device.type,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.device.type === compB.device.type
          ? 0
          : compA.device.type > compB.device.type
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.type),
      filters: getTypeFilter(filterOptions),
    },
    {
      title: 'Vendor',
      dataIndex: 'device.vendor',
      key: 'vendor',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.device.vendor,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.device.vendor === compB.device.vendor
          ? 0
          : compA.device.vendor > compB.device.vendor
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.vendor),
      filters: getVendorFilter(filterOptions),
    },
    {
      title: 'OS',
      dataIndex: 'device.operatingSystem',
      key: 'operatingSystem',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.device.operatingSystem,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.device.operatingSystem === compB.device.operatingSystem
          ? 0
          : compA.device.operatingSystem > compB.device.operatingSystem
            ? 1
            : -1,
      filterMultiple: true,
      filteredValue: stringifyArray(input.operatingSystem),
      filters: getOperatingSystemFilter(filterOptions),
    },
    {
      title: 'IP Addresses',
      dataIndex: 'device.ipAddresses',
      key: 'ipAddresses',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => (
        <span>
          {device.device.ipAddresses !== null &&
            device.device.ipAddresses.map((addr, idx) =>
              idx === 0 ? (
                <span key={addr}>{addr}</span>
              ) : (
                <span key={addr}>
                  <br />
                  {addr}
                </span>
              ),
            )}
        </span>
      ),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, device: DeviceQueryResult) => (
        <div className="center-table-cell">
          <DeviceActionMenu device={device} />
        </div>
      ),
    },
  ];

  const getColumns = () => {
    const cols = [...columns];

    if (isSysAdmin) {
      cols.splice(5, 0, {
        title: 'Managed',
        dataIndex: 'device.flags.managed.status',
        key: 'isManaged',
        showSorterTooltip: true,
        render: (_, device: DeviceQueryResult) =>
          isManaged(device) ? (
            <CheckCircleOutlined
              style={{
                color: 'green',
                fontSize: '20px',
              }}
            />
          ) : (
            <MinusCircleOutlined
              style={{
                fontSize: '20px',
              }}
            />
          ),
        sortDirections: ['ascend', 'descend'],
        sorter: (compA, compB) =>
          compA.device.flags.managed.status ===
          compB.device.flags.managed.status
            ? 0
            : compA.device.flags.managed.status >
                compB.device.flags.managed.status
              ? 1
              : -1,
        filterMultiple: false,
        filteredValue: nullifyIfEmpty(input.isManaged),
        filters: [
          {
            text: (
              <span>
                <CheckCircleOutlined
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                &nbsp;Is Managed
              </span>
            ),
            value: 'ACTIVE',
          },
          {
            text: (
              <span>
                <MinusCircleOutlined
                  style={{
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                &nbsp;Not Managed
              </span>
            ),
            value: 'INACTIVE',
          },
        ],
      });
    }

    cols.splice(6, 0, {
      title: 'Monitored',
      dataIndex: 'device.flags.monitored.status',
      key: 'isMonitored',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) =>
        device.device.flags.monitored.status === 'ACTIVE' ? (
          <CheckCircleOutlined
            style={{
              color: 'green',
              fontSize: '20px',
            }}
          />
        ) : (
          <MinusCircleOutlined
            style={{
              fontSize: '20px',
            }}
          />
        ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        compA.device.flags.monitored.status ===
        compB.device.flags.monitored.status
          ? 0
          : compA.device.flags.monitored.status >
              compB.device.flags.monitored.status
            ? 1
            : -1,
      filterMultiple: false,
      filteredValue: nullifyIfEmpty(input.isMonitored),
      filters: [
        {
          text: (
            <span>
              <CheckCircleOutlined
                style={{
                  color: 'green',
                  fontSize: '20px',
                  marginRight: '15px',
                }}
              />
              &nbsp;Is Monitored
            </span>
          ),
          value: 'ACTIVE',
        },
        {
          text: (
            <span>
              <MinusCircleOutlined
                style={{
                  fontSize: '20px',
                  marginRight: '15px',
                }}
              />
              &nbsp;Not Monitored
            </span>
          ),
          value: 'INACTIVE',
        },
      ],
    });

    return cols;
  };

  const getDevices = () => {
    if (deviceData !== null) {
      if (deviceData.devices !== null) {
        return deviceData.devices;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<DeviceQueryResult>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={getColumns()}
      dataSource={getDevices()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={(record) => record.device.id}
    />
  );
};

export default DeviceTable;
