import React, { useState } from 'react';
import { Form } from 'antd';

import {
  ActivityExtendedField,
  ExtendedFieldValue,
} from '@totem/types/ticketing';

const FormItem = Form.Item;

import '../ticketing.css';
import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import { isNotNull } from '@totem/utilities/common';
import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
};

type Props = {
  extendedField: ActivityExtendedField;
  extendedFieldValue: ExtendedFieldValue;
  onChange: (name: string, value: string, isValid: boolean) => void;
};

const ExtendedFieldEmailAutoComplete = ({
  extendedField,
  extendedFieldValue,
  onChange,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<string>(
    extendedFieldValue !== null ? extendedFieldValue.value : '',
  );

  const checkIsValid = checkValue => {
    if (extendedField.isRequired && checkValue.trim() === '') {
      return false;
    }
    return true;
  };

  const handleEmailChange = (value: string) => {
    setSelectedValue(value);
    onChange(extendedField.name, value, checkIsValid(value));
  };

  return (
    <FormItem
      label={extendedField.label}
      colon={false}
      style={styles.formItem}
      required={extendedField.isRequired}
      validateStatus={extendedFieldValue.isValid ? 'success' : 'error'}
    >
      <GenericAutoComplete
        type={'User'}
        label={''}
        limit={15}
        onSelect={(value) => handleEmailChange(value.display)}
        onChange={handleEmailChange}
        allowClear={true}
        allowFreeform={false}
        selectedValue={isNotNull(selectedValue) ? {display: selectedValue, value: EMPTY_ID} : {display: '', value: ''}}
      />
    </FormItem>
  );
};

export default ExtendedFieldEmailAutoComplete;
