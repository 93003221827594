import React, { useState } from 'react';
import {
  CloseCircleFilled,
  PlusOutlined,
  SnippetsOutlined,
} from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Card, Flex, Modal } from 'antd';
import * as R from 'ramda';

import DetailSpinner from '@totem/components/common/DetailSpinner';
import SideBarTitle from '@totem/components/SideBarTitle';
import client from '@totem/graph/client';
import {
  CREATE_NOTE,
  DELETE_NOTE,
  GET_NOTES,
  UPDATE_NOTE,
} from '@totem/graph/note';
import { useConnection } from '@totem/hooks/useConnection';
import { useErrorNotification } from '@totem/hooks/useErrorNotification';
import colors from '@totem/styles/colors';
import { Note as NoteType, NotesConnection } from '@totem/types/note';

import AddEditNoteModal from './AddEditNoteModal';
import Note from './Note';

import './notes.css';

export interface Props {
  subjectId: string;
  title: string;
  noContentMessage: string;
  onCreate?: (note: NoteType) => void;
  onDelete?: (id: string) => void;
}

const styles = {
  card: {
    width: '100%',
  },
  cardHeader: {
    borderTop: `5px solid`,
    borderImage: `${colors.gradient.accent} 100% 0 0 0`,
    //borderImage: `${colors.gradient.turquoise} 100% 0 0 0`,
  },
  cardBody: {
    padding: '2.4rem',
    paddingBottom: '0',
    borderTop: `1px solid ${colors.antd.borderGray}`,
  },
  icon: {
    fontSize: '4rem',
    color: colors.neutral.silver,
  },
  errorIcon: {
    color: colors.utility.error,
  },
};

const Notes = ({
  subjectId,
  title,
  noContentMessage,
  onCreate,
  onDelete,
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  const [selectedNote, setSelectedNote] = useState<NoteType | null>(null);

  const { data, refetch, loading } = useQuery(GET_NOTES, {
    variables: { input: { subjectIds: [subjectId] } },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  });

  const { notes } = useConnection<NotesConnection>({
    data,
    accessor: R.path(['notes']),
    initialData: { totalCount: 0, notes: [] },
  });

  const [createNote, { loading: loadingCreateNote, error: createNoteError }] =
    useMutation<{ noteCreate: NoteType }>(CREATE_NOTE, {
      onCompleted: ({ noteCreate: note }) => {
        if (onCreate) {
          onCreate(note);
        }
      },
    });

  useErrorNotification(createNoteError, 'Error Creating Note');

  const [updateNote, { loading: loadingUpdateNote, error: updateNoteError }] =
    useMutation(UPDATE_NOTE);

  useErrorNotification(updateNoteError, 'Error Updating Note');

  const [deleteNote, { error: deleteNoteError }] = useMutation(DELETE_NOTE);

  useErrorNotification(deleteNoteError, 'Error Deleting Note');

  const handleClose = () => {
    setSelectedNote(null);
    setOpen(false);
  };

  const handleEdit = (note: NoteType) => {
    setSelectedNote(note);
    setOpen(true);
  };

  const handleDelete = (note: NoteType) => {
    Modal.confirm({
      content: 'Are you sure you want to delete this note?',
      title: 'Delete this note?',
      cancelText: 'No',
      okText: 'Yes, Delete',
      okType: 'danger',
      icon: <CloseCircleFilled style={styles.errorIcon} />,
      onOk: () =>
        deleteNote({ variables: { id: note.id } }).then(() => {
          if (onDelete) {
            onDelete(note.id);
          }

          client.cache.evict({ id: client.cache.identify(note) });
          client.cache.gc();
        }),
    });
  };

  // eslint-disable-next-line no-shadow
  const handleSubmit = async (title: string, body: string) => {
    if (!selectedNote) {
      await createNote({ variables: { input: { subjectId, title, body } } });
      await refetch();
      setOpen(false);
    } else {
      await updateNote({
        variables: {
          input: {
            id: selectedNote.id,
            title,
            body,
          },
        },
      });

      setOpen(false);
      setSelectedNote(null);
    }
  };

  return (
    <>
      <Card
        title={
          <SideBarTitle>
            <div>{title}</div>
            <Button
              shape="circle"
              icon={<PlusOutlined style={{ color: colors.brand.blue }} />}
              onClick={() => setOpen(true)}
            />
        </SideBarTitle>
        }
        style={styles.card}
        styles={{body: {
            ...styles.cardBody,
            ...(!R.isEmpty(notes) && {
              padding: '2.4rem 0rem',
            }),
          }, header: styles.cardHeader}}
      >
        {loading ? (
          <div styleName="spinner-container">
            <DetailSpinner />
          </div>
        ) : R.isEmpty(notes) ? (
          <div styleName="no-system-container">
            <SnippetsOutlined style={styles.icon} />
            <div styleName="no-system-subtext">{noContentMessage}</div>
            <Button type="primary" onClick={() => setOpen(true)}>
              Add Note
            </Button>
          </div>
        ) : (
          <div styleName="container">
            {
              // eslint-disable-next-line no-shadow
              notes.map((note) => (
                <Note
                  key={note.id}
                  note={note}
                  onEditClick={handleEdit}
                  onDeleteClick={handleDelete}
                />
              ))
            }
          </div>
        )}
      </Card>
      <AddEditNoteModal
        open={open}
        onClose={handleClose}
        loading={loading || loadingCreateNote || loadingUpdateNote}
        onSubmit={handleSubmit}
        note={selectedNote}
      />
    </>
  );
};

export default Notes;
