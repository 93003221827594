import React, { useCallback, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Tabs,
  TabsProps,
} from 'antd';

import {
  Contract,
  ContractLine,
  ContractService,
  ContractServiceLevelAgreement,
} from '@totem/types/contracts';
const FormItem = Form.Item;
const { Option } = Select;

import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import ContractLineDevicesEditPanelAC from '@totem/components/contracts/edits/ContractLineDeviceEditPanelAC';
import ContractLineServiceLevelAgreementsEditPanel from '@totem/components/contracts/edits/ContractLineServiceLevelAgreementsEditPanel';
import ContractLineServicesEditPanel from '@totem/components/contracts/edits/ContractLineServicesEditPanel';
import ModalFooter from '@totem/components/ModalFooter';
import ModalTitle from '@totem/components/ModalTitle';
import TabTitle from '@totem/components/TabTitle';
import { ObjectReference } from '@totem/types/common';
import { MIN_DATE_TIME_STRING } from '@totem/types/ticketing';
import { getToken } from '@totem/utilities/accountUtilities';
import { isNotNull } from '@totem/utilities/common';
import { ParseStringDate } from '@totem/utilities/dateUtilities';
import { CONTRACTS_ENDPOINT } from '@totem/utilities/endpoints';

import '../contracts.css';
import GenericAutoComplete from '@totem/components/common/autoCompletes/GenericAutoComplete';
import { AutoCompleteValue } from '@totem/types/autoComplete';

interface Building {
  id: string;
  name: string;
  region: {
    id: string;
    name: string;
  };
}

const styles = {
  form: {
    width: '100%',
  },
  formItem: {
    paddingBottom: '0',
    marginBottom: '1rem',
  },
  indent: {
    marginLeft: '20px',
  },
};

const defaultContractLine: ContractLine = {
  id: '',
  number: 0,
  services: [],
  serviceLevelAgreements: [],
  contractLineStart: MIN_DATE_TIME_STRING,
  contractLineEnd: MIN_DATE_TIME_STRING,
  renewable: false,
  serviceAddress: null,
  quantity: 0,
  status: 'Active',
  devices: [],
  keys: [],
  billingAmount: 0,
  attributes: [],
};

export interface Props {
  visible: boolean;
  contractId: string;
  billingContainerId: string;
  contractLine?: ContractLine;
  onContractLineChanged?: (contractLine: ContractLine) => void;
  onContractChanged?: (contract: Contract) => void;
  onClose: (updated: boolean) => void;
}

const getSalesOrderNumber = (cl: ContractLine) => {
  if (isNotNull(cl) && isNotNull(cl.keys)) {
    const key = cl.keys.find(
      (chk) =>
        chk.system === 'NetSuite' &&
        chk.instance === 'NETSUITE_SALES_ORDER_NUMBER',
    );
    if (isNotNull(key)) {
      return key.key;
    }
  }

  return '--';
};

const ContractLineEditModal = ({
  visible,
  contractId,
  billingContainerId,
  contractLine,
  onContractChanged,
  onContractLineChanged,
  onClose,
}: Props) => {
  const [isSending, setIsSending] = useState(false);
  const [contractLineData, setContractLineData] = useState<ContractLine>(
    typeof contractLine !== 'undefined' && contractLine !== null
      ? contractLine
      : defaultContractLine,
  );
  const salesOrderNumber = getSalesOrderNumber(contractLine);
  const contractLineDisplay = `${salesOrderNumber} : ${contractLine.number}`;

  const handleBuildingChange = (building: string) => {
    const newSerivceAddress: ObjectReference = {
      id: EMPTY_ID,
      name: building,
    };

    setContractLineData({
      ...contractLineData,
      serviceAddress: newSerivceAddress,
    });
  };

  const handleBuildingSelect = (selectedBuilding: AutoCompleteValue) => {
    const newSerivceAddress: ObjectReference = {
      id: selectedBuilding.value,
      name: selectedBuilding.display,
    };

    setContractLineData({
      ...contractLineData,
      serviceAddress: newSerivceAddress,
    });
  };

  const saveContractLine = useCallback(async (contractDataToSend) => {
    if (isSending) {
      return;
    }
    setIsSending(true);

    fetch(
      `${CONTRACTS_ENDPOINT}/${contractId}/billingContainer/${billingContainerId}/lines`,
      {
        method: 'PUT',
        headers: new Headers({
          Authorization: `Bearer ${getToken()}`,
        }),
        body: JSON.stringify(contractDataToSend),
      },
    )
      .then((res) => res.json())
      .then((result: Contract) => {
        if (
          typeof onContractLineChanged !== 'undefined' &&
          onContractLineChanged !== null
        ) {
          onContractLineChanged(contractDataToSend);
        }

        if (
          typeof onContractChanged !== 'undefined' &&
          onContractChanged !== null
        ) {
          onContractChanged(result);
        }

        onClose(true);
      })
      .then(() => {
        setIsSending(false);
      });
  }, []);

  const handleStaticChange = (name: string, value: string) => {
    setContractLineData({ ...contractLineData, [name]: value });
  };

  const isDataValid = () => {
    return true;
  };

  const handleSubmit = () => {
    if (isDataValid()) {
      saveContractLine(contractLineData);
    }
  };

  const getContractLineDescription = () => {
    if (
      typeof contractLineData !== 'undefined' &&
      contractLineData !== null &&
      typeof contractLineData.attributes !== 'undefined' &&
      contractLineData.attributes !== null
    ) {
      for (let idx = 0; idx < contractLineData.attributes.length; idx++) {
        if (contractLineData.attributes[idx].name === 'LINE_DESCRIPTION') {
          return contractLineData.attributes[idx].value;
        }
      }
    }
    return '';
  };

  const getContractLineAddress = () => {
    if (
      typeof contractLineData !== 'undefined' &&
      contractLineData !== null &&
      typeof contractLineData.attributes !== 'undefined' &&
      contractLineData.attributes !== null
    ) {
      for (let idx = 0; idx < contractLineData.attributes.length; idx++) {
        if (contractLineData.attributes[idx].name === 'SHIPPING_ADDRESS') {
          return contractLineData.attributes[idx].value;
        }
      }
    }
    return '';
  };

  const tabItems: TabsProps['items'] = [
    {
      key: 'services',
      label: <TabTitle>Services</TabTitle>,
      children: (
        <ContractLineServicesEditPanel
          contractLineId={contractLineData.id}
          services={contractLineData.services}
          onServicesChanged={(serviceData: ContractService[]) => {
            setContractLineData({
              ...contractLineData,
              services: serviceData,
            });
          }}
        />
      ),
    },
    {
      key: 'service_level_agreements',
      label: <TabTitle>Service Level Agreements</TabTitle>,
      children: (
        <ContractLineServiceLevelAgreementsEditPanel
          contractLineId={contractLineData.id}
          serviceLevelAgreements={contractLineData.serviceLevelAgreements}
          onServiceLevelAgreementsChanged={(
            serviceLevelAgreementData: ContractServiceLevelAgreement[],
          ) => {
            setContractLineData({
              ...contractLineData,
              serviceLevelAgreements: serviceLevelAgreementData,
            });
          }}
        />
      ),
    },
    {
      key: 'devices',
      label: <TabTitle>Devices</TabTitle>,
      children: (
        <ContractLineDevicesEditPanelAC
          contractLineId={contractLineData.id}
          buildingId={
            typeof contractLineData.serviceAddress !== 'undefined' &&
            contractLineData.serviceAddress !== null
              ? contractLineData.serviceAddress.id
              : EMPTY_ID
          }
          devices={contractLineData.devices}
          onDevicesChanged={(deviceData: ObjectReference[]) => {
            setContractLineData({
              ...contractLineData,
              devices: deviceData,
            });
          }}
        />
      ),
    },
  ];

  return (
    <Modal
      open={visible}
      onCancel={() => onClose(false)}
      title={<ModalTitle>Contract Line: {contractLineDisplay}</ModalTitle>}
      confirmLoading={false}
      width={'40%'}
      footer={
        <ModalFooter>
          <Button onClick={() => onClose(false)}>Close</Button>
          <Button onClick={handleSubmit} type="primary">
            Submit
          </Button>
        </ModalFooter>
      }
    >
      <div styleName="section-underline">
        <Row>
          <Col span={12}>
            <b>Description:</b>
            <br />
            {getContractLineDescription()}
          </Col>
          <Col span={12}>
            <b>Address:</b>
            <br />
            {getContractLineAddress()}
          </Col>
        </Row>
        <br />
      </div>
      <br />
      <Form layout="vertical" style={styles.form}>
        <Row>
          <Col span={8}>
            <FormItem
              label="Contract Line Start"
              colon={false}
              style={styles.formItem}
              required
            >
              <DatePicker
                name="contractLineStart"
                // @ts-ignore
                value={ParseStringDate(contractLineData.contractLineStart)}
                format="YYYY-MM-DD"
                onChange={(date) =>
                  handleStaticChange('contractLineStart', date.toISOString())
                }
              />
            </FormItem>
            <FormItem
              label="Contract Line End"
              colon={false}
              style={styles.formItem}
              required
            >
              <DatePicker
                name="contractLineStart"
                // @ts-ignore
                value={ParseStringDate(contractLineData.contractLineEnd)}
                format="YYYY-MM-DD"
                onChange={(date) =>
                  handleStaticChange('contractLineEnd', date.toISOString())
                }
              />
            </FormItem>
            <FormItem
              label="Status"
              colon={false}
              style={styles.formItem}
              required
            >
              <Select
                onChange={(value: string) =>
                  handleStaticChange('status', value)
                }
                defaultValue={contractLineData.status}
                value={contractLineData.status}
              >
                <Option value="Active">Active</Option>
                <Option value="Canceled">Canceled</Option>
                <Option value="Credit Hold">Credit Hold</Option>
                <Option value="Expired">Expired</Option>
                <Option value="Past Due">Past Due</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span={8}>
            <FormItem
              label="Service Location"
              colon={false}
              style={styles.formItem}
              required
            >
              <GenericAutoComplete
                type={'Building'}
                label={''}
                limit={15}
                onSelect={handleBuildingSelect}
                onChange={handleBuildingChange}
                allowClear={true}
                allowFreeform={false}
                selectedValue={isNotNull(contractLineData.serviceAddress) ? {display: contractLineData.serviceAddress.name, value: contractLineData.serviceAddress.id} : {display: '', value: ''}}
              />
            </FormItem>
          </Col>
          <Col span={2} />
          <Col span={6}>
            <FormItem label="Quantity" colon={false} style={styles.formItem}>
              <InputNumber
                defaultValue={contractLineData.quantity}
                min={0}
                onChange={(newQuantity: number) => {
                  setContractLineData({
                    ...contractLineData,
                    quantity: newQuantity,
                  });
                }}
              />
            </FormItem>
            <FormItem label="Renewable" colon={false} style={styles.formItem}>
              <Checkbox
                checked={contractLineData.renewable}
                onChange={(evt: CheckboxChangeEvent) => {
                  setContractLineData({
                    ...contractLineData,
                    renewable: evt.target.checked,
                  });
                }}
              />
            </FormItem>
          </Col>
        </Row>
        <Tabs defaultActiveKey="services" items={tabItems} />
      </Form>
      <br />
    </Modal>
  );
};

export default ContractLineEditModal;
